import React, { useState } from 'react'
import FaqApi from '../../api/FaqApi'

const FaqSection = () => {
    const [expandedIndex, setExpandedIndex] = useState(null);
    return (
        <>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="accordion" id="accordion">
                                {FaqApi.map((item, index) => (
                                    <AccordionItem
                                        key={index}
                                        index={index}
                                        title={item.title}
                                        content={item.content}
                                        expandedIndex={expandedIndex}
                                        setExpandedIndex={setExpandedIndex}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default FaqSection
const AccordionItem = ({ title, content, index, expandedIndex, setExpandedIndex }) => {
    const isExpanded = index === expandedIndex;

    return (
        <div className="accordion-item bg-transparent mb-4">
            <h3 className="accordion-header" id={`heading${index}`}>
                <button
                    className={`accordion-button shadow-none mb-0 bg-transparent ${isExpanded ? '' : 'collapsed'}`}
                    type="button"
                    onClick={() => setExpandedIndex(isExpanded ? null : index)}
                    aria-expanded={isExpanded}
                    aria-controls={`collapse${index}`}
                >
                    {title}
                </button>
            </h3>
            <div
                id={`collapse${index}`}
                className={`accordion-collapse border-0 collapse ${isExpanded ? 'show '  : ''}`}
                aria-labelledby={`heading${index}`}
            >
                <div className="accordion-body">{content}</div>
            </div>
        </div>
    );
};
