import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link, useNavigate } from 'react-router-dom';
import TeamMembersApi from '../../api/TeamMembersApi/TeamMembersApi';
import { NavLink } from 'react-router-dom';

const TeamMember = () => {
    const navigate = useNavigate();
    const SinglePage = (member) => {
        localStorage.setItem('team', JSON.stringify(member));
        navigate(`/team/${member.id}`)
    }
    return (
        <section className="pt-0 mt-5">
            <div className="container">
                <div className="row justify-content-center text-center mb-5">
                    <div className="col-lg-8 col-md-12">
                        <div className="theme-title">
                            <h6>Expert Team Member</h6>
                            <h2>We Do Awesome Services For Our Client</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col d-flex justify-content-evenly">
                        <OwlCarousel
                            className="owl-carousel"
                            items={4}
                            loop={true}
                            responsive={{
                                0: { items: 1 },
                                576: { items: 2 },
                                768: { items: 3 },
                                992: { items: 4 },
                            }}
                            dots={false}
                            nav
                            autoplay
                            margin={20}
                            navText={[
                                "<span class='owl-nav-icon las la-long-arrow-alt-left' style={{ backgroundColor: 'white' }}></span>",
                                "<span class='owl-nav-icon las la-long-arrow-alt-right' style={{ backgroundColor: 'white' }} ></span>"
                            ]}
                        >
                            {TeamMembersApi.map((member, index) => (
                                <div className="item" key={index}>
                                    <div className="team-member">
                                        <h4 className="member-name text-decoration-none" onClick={() => SinglePage(member)}>
                                            <NavLink>{member.name} </NavLink>
                                        </h4>
                                        <span>{member.role}</span>
                                        <ul className="team-social-icon list-inline d-flex justify-content-start">
                                            {member.social.map((icon, i) => (
                                                <li key={i} className="pe-1">
                                                    <Link to="">
                                                        <i className={`la la-${icon}`}></i>
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                        <div className="team-images">
                                            <img src={member.image} className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TeamMember;