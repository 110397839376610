import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import CarouseApi3 from '../../api/CarouselApi/CarouseApi3';

const Carousel3 = () => {
    return (
        <OwlCarousel
            className="owl-carousel"
            items={3}
            loop={true}
            dots={true}
            responsive={{
                0: { items: 1 },
                576: { items: 1 },
                768: { items: 2 },
                992: { items: 2 },
                1200: { items: 3 }
            }}
            margin={30}
            autoplay
        >
            {CarouseApi3.map((service, index) => (
                <div className="item" key={index}>
                    <div className="service-item style-2">
                        <div className="service-image">
                            <img src={service.imageSrc} className="img-fluid" alt={service.title} />
                        </div>
                        <div className="service-desc">
                            <h4>
                                <Link to={service.link} className="text-decoration-none text-reset" >{service.title}</Link>
                            </h4>
                            <p>{service.desc}</p>
                            <Link className="rounded-button" to={service.link}>
                                <i className="flaticon-forward"></i>
                            </Link>
                            <div className="service-icon">
                                <i className={service.iconClass}></i>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </OwlCarousel>
    )
}

export default Carousel3