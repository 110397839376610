const AboutApi2 = [
    {
      icon: "flaticon flaticon-statistics",
      title: "Marketing & Reporting",
      description:
        "Spend less time writing a plan and more time building your business. We help you communicate your idea.",
    },
    {
      icon: "flaticon flaticon-idea",
      title: "Creative Design",
      description:
        "Spend less time writing a plan and more time building your business. We help you communicate your idea.",
    },
    {
      icon: "flaticon flaticon-rocket-launch",
      title: "SEO Friendly",
      description:
        "Spend less time writing a plan and more time building your business. We help you communicate your idea.",
    },
    {
      icon: "flaticon flaticon-announcement",
      title: "24/7 Support",
      description:
        "Spend less time writing a plan and more time building your business. We help you communicate your idea.",
    },
  ];
  export default AboutApi2