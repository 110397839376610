import testimonial from "../../assets/images/testimonial/01.jpg";
const CarouselApi2 = [
  {
    name: "Maria Bella",
    position: "Manager",
    icon: "flaticon-quotes",
    description:
      " Look no further, this is the company to go with. Whata pleasure it is to deal with friendly, professional individuals who take pride in their work. I highly recommend Starbiz Look no further, this is the company to go with.",
    img: testimonial,
  },
  {
    name: "Maria Bella",
    position: "Manager",
    icon: "flaticon-quotes",
    description:
      " Look no further, this is the company to go with. Whata pleasure it is to deal with friendly, professional individuals who take pride in their work. I highly recommend Starbiz Look no further, this is the company to go with.",
    img: testimonial,
  },
  {
    name: "Maria Bella",
    position: "Manager",
    icon: "flaticon-quotes",
    description:
      " Look no further, this is the company to go with. Whata pleasure it is to deal with friendly, professional individuals who take pride in their work. I highly recommend Starbiz Look no further, this is the company to go with.",
    img: testimonial,
  },
  {
    name: "Maria Bella",
    position: "Manager",
    icon: "flaticon-quotes",
    description:
      " Look no further, this is the company to go with. Whata pleasure it is to deal with friendly, professional individuals who take pride in their work. I highly recommend Starbiz Look no further, this is the company to go with.",
    img: testimonial,
  },
];
export default CarouselApi2;
