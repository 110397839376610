import React from 'react';
import {  Modal, ModalBody, } from 'reactstrap';

const OpneModal = ({ closeModal, isOpen, toggleModal }) => {
    return (
        <>
            <div>
                <Modal isOpen={isOpen} toggle={closeModal} centered style={{ maxWidth: "40%", margin: "auto", maxHeight: "40%" }}>
                    <ModalBody style={{ height: "30rem" }}>
                        <iframe
                            title="Video"
                            src="https://www.youtube.com/embed/QYPkflwuk9s"
                            allowFullScreen
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                            }}
                        />
                    </ModalBody>
                </Modal>
            </div>
        </>
    );
};

export default OpneModal;
