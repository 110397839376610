import React from 'react'
import BlogLeftApi from '../../api/BlogApi/BlogLeftApi'
import SaidbarApi from '../../api/SaidbarApi'
import { useNavigate } from 'react-router-dom'
import { NavLink } from 'react-router-dom'

const Sidebar = () => {
  const navigate = useNavigate()
  const blogSingle = (item, index) => {
    localStorage.setItem('blog', JSON.stringify(item.imageSrc));
    navigate(`/blog-single/${index + 1}`)
  }
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    // Add your custom logic here, such as searching
    console.log('Form submitted');
  }
  return (
    <>
      <div className="theme-sidebar">
        <div className="theme-widget widget widget_search">
          <form role="search" method="get" id="search-form" className="search-form"
            onSubmit={handleSubmit}>
            <label>
              <input type="search" className="search-field" placeholder="Search Here" name="s" />
            </label>
            <button type="submit" className="search-submit">
              <i className="las la-search" />
            </button>
          </form>
        </div>
        <div className="theme-widget widget widget_theme_recentpost">
          <h2 className="mb-3">Recent Stories</h2>
          <ul>
            {BlogLeftApi.map((post, index) => (
              <li key={index}>
                <div className="theme-post-image">
                  <img src={post.imageSrc} className="img-fluid" alt="..." />
                </div>
                <div className="theme-post-content">
                  <div onClick={() => blogSingle(post, index)}>
                    <NavLink className='cursor-pointer'>{post.title}</NavLink>
                  </div>
                  <span className="theme-post-date">{post.date}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="theme-widget widget widget_categories">
          <h2 className="mb-3">Categories</h2>
          <ul className="list-unstyled">
            {SaidbarApi.map((category, index) => (
              <li key={index} className="mb-3">
                <NavLink >
                  {category.name} <span className="text-theme">(1)</span>
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
        <div className="theme-widget widget widget_tag_cloud">
          <h2 className="mb-3">Tags</h2>
          <div className="tagcloud">
            {SaidbarApi.map((tag, index) => (
              <NavLink key={index} onClick={() => blogSingle(tag, index)}>
                {tag.name}
              </NavLink>
            ))}
          </div>
        </div>
      </div>

    </>
  )
}

export default Sidebar
