import portfolio1 from "../../assets/images/portfolio/01.jpg";
import portfolio2 from "../../assets/images/portfolio/02.jpg";
import portfolio3 from "../../assets/images/portfolio/03.jpg";
import portfolio4 from "../../assets/images/portfolio/04.jpg";
import portfolio5 from "../../assets/images/portfolio/05.jpg";
const CarouselApi = [
  { fild: "Development", position: "Web Development", img: portfolio1 },
  { fild: "Development", position: "SEO Optimization", img: portfolio2 },
  { fild: "Technology", position: "SEO Marketing", img: portfolio3 },
  { fild: "Branding", position: "Smart Branding", img: portfolio4 },
  { fild: "Branding", position: "Smart Branding", img: portfolio5 },
];
export default CarouselApi;
