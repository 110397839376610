export const HowWeWorks = [
    {
      list: "Your Business Skills But Never Stop Improving."
    },
  
    {
      list: "Tactics That Can Help Your Business Grow."
    }, 
  
    {
      list: "Fast-Track Your business"
    }, 
  
    {
      list: "A Strategic Path of True Investment"
    }, 
     {
      list: "Listening Your Needs for IT Strategy"
    }, 
    {
      list: "Solutions Tailored to Your Needs"
    },
  ]
  