import React, { useEffect, useState } from "react";
import Header from "./Layout/header/Header";
import { Route, Routes, useLocation } from "react-router-dom";
import "./Vender";
import HomeOne from "./Pages/home/HomeOne";
import Footer from "./Layout/footer/Footer";
import HomeTwo from "./Pages/home/HomeTwo";
import HomeThree from "./Pages/home/HomeThree";
import AboutUs from "./Pages/pages/AboutUs";
import Team from "./Pages/pages/Team";
import TeamSingle from "./Pages/pages/TeamSingle";
import PriceTable from "./Pages/pages/PriceTable";
import Faq from "./Pages/pages/Faq";
import ComingSoon from "./Pages/pages/ComingSoon";
import ErrorPage from "./Pages/pages/ErrorPage";
import UiUxExperience from "./Pages/service/UiUxExperience";
import Portfoliostyleone from "./Pages/portfolio/Portfolio_style_one";
import Portfoliostyletwo from "./Pages/portfolio/Portfolio_style_two";
import Portfoliosingle from "./Pages/portfolio/portfolio_single";
import BlogStyle1 from "./Pages/blogs/Blog_Style_1";
import Blogleftsidebar from "./Pages/blogs/Blog_Left_Sidebar";
import BlogRightSidebar from "./Pages/blogs/Blog_Right_Sidebar";
import BlogSingle from "./Pages/blogs/Blog_Single";
import ContactUs from "./Pages/ContactUs/ContactUs";
import DigitalMarketing from "./Pages/service/DigitalMarketing";
import MobileDevelopment from "./Pages/service/MobileDevelopment";
import SocialMediaMarketing from "./Pages/service/SocialMediaMarketing";
import WebsiteDevelopment from "./Pages/service/WebsiteDevelopment";
import BusinessStrategy from "./Pages/service/BusinessStrategy";

const App = () => {
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();
  // screen change and scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const shouldHideHeaderFooter = ["/coming-soon", "/error-404"].includes(pathname);
  // console.log("🚀 ~ file: App.js:44 ~ App ~ shouldHideHeaderFooter:", shouldHideHeaderFooter)
  // screen change and scroll to top

  //loader
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [pathname]);

  //loader
  return (
    <>
      {loading && <div id="ht-preloader">
        <div className="loader clear-loader">
          <img className="img-fluid" src={require("./assets/images/loader.png")} alt="Lodaer Img" />
        </div>
      </div>
      }
      <div class="page-wrapper">
        {!shouldHideHeaderFooter && <Header />}
        <Routes>
          {/* Home */}
          <Route path="/" element={<HomeOne />} />
          <Route path="/home-two" element={<HomeTwo />} />
          <Route path="/home-three" element={<HomeThree />} />
          {/* Home */}

          {/* Pages */}
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/team" element={<Team />} />
          <Route path="/team-single" element={<TeamSingle />} />
          <Route path="/team/:id" element={<TeamSingle />} />
          <Route path="/pricing" element={<PriceTable />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/coming-soon" element={<ComingSoon />} />
          <Route path="*" element={<ErrorPage />} />
          {/* Pages */}

          {/* service */}
          <Route path="/ui-ux-experience" element={<UiUxExperience />} />
          <Route path="/digital-marketing" element={<DigitalMarketing />} />
          <Route path="/mobile-development" element={<MobileDevelopment />} />
          <Route path="/social-media-marketing" element={<SocialMediaMarketing />} />
          <Route path="/website-development" element={<WebsiteDevelopment />} />
          <Route path="/business-strategy" element={<BusinessStrategy />} />
          {/* service */}

          {/* portfolio */}
          <Route path="/portfolio-style-one" element={<Portfoliostyleone />} />
          <Route path="/portfolio-style-two" element={<Portfoliostyletwo />} />
          <Route path="/portfolio-single" element={<Portfoliosingle />} />
          <Route path="/portfolio-single/:id" element={<Portfoliosingle />} />
          {/* portfolio */}

          {/* Blog */}
          <Route path="/blog-style-one" element={<BlogStyle1 />} />
          <Route path="/blog-left-sidebar" element={<Blogleftsidebar />} />
          <Route path="/blog-right-sidebar" element={<BlogRightSidebar />} />
          <Route path="/blog-single" element={<BlogSingle />} />
          <Route path="/blog-single/:id" element={<BlogSingle />} />
          {/* Blog */}

          {/* ContactUs */}
          <Route path="/contact-us" element={<ContactUs />} />
          {/* ContactUs */}
        </Routes>

        {!shouldHideHeaderFooter && <Footer />}
      </div>
    </>
  );
};

export default App;
