export const BlogApi2 = [
    {
        id: 1,
        title: "Why Choose Us For Starbiz?",
        details: "For the incapable bliss of present souls like mine. I am so happy, my dear friend, so absorbed in the exquisite sense of mere tranquil existence, that I neglect my talents. I should be incapable…"
    },
    {
        id: 2,
        title: "What Are Your Terms And Conditions?",
        details: "For the incapable bliss of present souls like mine. I am so happy, my dear friend, so absorbed in the exquisite sense of mere tranquil existence, that I neglect my talents. I should be incapable…"
    },
    {
        id: 3,
        title: "Prioritize the features your customers need",
        details: "For the incapable bliss of present souls like mine. I am so happy, my dear friend, so absorbed in the exquisite sense of mere tranquil existence, that I neglect my talents. I should be incapable…"
    },
    {
        id: 4,
        title: "How to Purchase Extend Licence of this Product?",
        details: "For the incapable bliss of present souls like mine. I am so happy, my dear friend, so absorbed in the exquisite sense of mere tranquil existence, that I neglect my talents. I should be incapable…"
    },
    {
        id: 5,
        title: "How to Manage your Own Dashboard?",
        details: "For the incapable bliss of present souls like mine. I am so happy, my dear friend, so absorbed in the exquisite sense of mere tranquil existence, that I neglect my talents. I should be incapable…"
    }
]
