/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import AgencySvg from "../svg/Agency";
const HeroSection2 = () => {
  return (
    <div>
      <section className="position-relative banner-video" data-overlay="9">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-10 col-lg-12 col-12">
              <h1>Crafting Best</h1>
              <div className="banner-video-text2 my-5 text-end">
                <h1>Creative Design</h1>
              </div>
              <h1>Deliver Agency</h1>
            </div>
            <div className="col-xl-2 col-lg-12 col-12 text-end">
              <div className="round-text ms-auto">
                <AgencySvg label={"Creative Agency • Business Agency •"} icon={"flaticon-brand"} />
                <div class="text-btn-icon">
                  <i aria-hidden="true" class="flaticon flaticon-brand"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="video-bg">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/ooXO4LInMW0?autoplay=1&mute=1"
            frameBorder="0"
            allow="accelerometer; autoplay; modestbranding; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </section>
    </div>
  );
};
export default HeroSection2;
