import React from 'react'
import HeroSection4 from '../../Components/heroSection/HeroSection4'
import Project1 from '../../Components/projectSection/Project1'

const Portfolio_style_two = () => {
    return (
        <>
            <HeroSection4 name={"portfolio-style-two"} name2={"Home"} />
            <div class="page-content">
                <Project1 margin={"mt-10"} />
            </div>
        </>
    )
}

export default Portfolio_style_two
