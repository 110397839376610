import client1 from '../../assets/images/client/01.png'
import client2 from '../../assets/images/client/02.png'
import client3 from '../../assets/images/client/03.png'
import client4 from '../../assets/images/client/04.png'
import client5 from '../../assets/images/client/05.png'
import client6 from '../../assets/images/client/06.png'

const CarouseApi4 = [
    {
        imageSrc: client1,
    },
    {
        imageSrc: client2,
    },
    {
        imageSrc: client3,
    },
    {
        imageSrc: client4,
    },
    {
        imageSrc: client5,
    },
    {
        imageSrc: client6,
    }
]

export default CarouseApi4;