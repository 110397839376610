import React from "react";
import AboutApi2 from "../../api/AboutApi2";

const AboutSection2 = () => {
  return (
    <>
      <section className="pb-0">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-4">
              <div className="theme-title">
                <h6>What We Do</h6>
                <h2>We Are Starbiz Best Creative Agency Ever</h2>
              </div>
            </div>
            <div className="col-12 col-lg-8 ps-lg-8 mt-5 mt-lg-0">
              <div className="row">
                {AboutApi2.map((feature, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="featured-item style-1">
                      <div className="featured-icon">
                        <i aria-hidden="true" className={feature.icon}></i>
                      </div>
                      <div className="featured-description">
                        <div className="featured-title">
                          <h5>{feature.title}</h5>
                        </div>
                        <p>{feature.description}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutSection2;
