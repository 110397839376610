import React, { useState, useEffect } from 'react';
const Timer = ({ targetDate }) => {
    const [timeRemaining, setTimeRemaining] = useState(targetDate - Date.now());
    useEffect(() => {
        const interval = setInterval(() => {
            const now = Date.now();
            setTimeRemaining(targetDate - now);

            if (timeRemaining <= 0) {
                clearInterval(interval);
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [targetDate, timeRemaining]);
    const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);
    return (
        <div className="countdown list-inline d-flex justify-content-between mb-0">
            <li><span>{days}</span><p>Days</p></li>
            <li><span>{hours}</span><p>hours</p></li>
            <li><span>{minutes}</span><p>minutes</p></li>
            <li><span>{seconds}</span><p>seconds</p></li>
        </div>
    );
};
export default Timer;
