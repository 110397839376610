import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
  const [Visible, setVisible] = useState(false)
  const [loader, setLoader] = useState(true);
  const gototop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }
  const [inputValue, setInputValue] = useState({
    email: "",
  })

  const onInputChange = (evt) => {
    const { name, value } = evt.target;
    setInputValue((prev) => ({ ...prev, [name]: value }))
  }
  const handleScroll = () => {
    var scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    if (scrollTop > 100) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (loader === true) {
      setTimeout(() => setLoader(false), 2000);
    }
  }, [loader]);
  return (<>
    <footer className="footer">
      <div className="primary-footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 mt-5 mt-lg-0">
              <NavLink
                className="footer-logo d-inline-block logo mb-5"
                to={"/"}
              >
                <img
                  className="img-fluid"
                  src={require("../../assets/images/logo.png")}
                  alt="Logo Img"
                />
              </NavLink>
              <div className="footer-cntct">
                <ul className="media-icon list-unstyled">
                  <li className="">
                    <i className="flaticon-location" />
                    <p className="mb-0">
                      5th Street, 21st Floor, New York, USA
                    </p>
                  </li>
                  <li>
                    <i className="flaticon-chat" />
                    <NavLink to="mailto:info@skytouchinfotech.com">info@skytouchinfotech.com</NavLink>
                  </li>
                  <li>
                    <i className="flaticon-phone-call" />
                    <NavLink to="tel:+912345678900">+91-234-567-8900</NavLink>
                  </li>
                  <li>
                    <i className="flaticon-calendar" />
                    <p className="mb-0">Monday - Friday: 10:00 to 6:00</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <h5 className="mb-4">Our Services</h5>
              <ul className="list-unstyled mb-0 footer-menu">
                <li className="mb-3">
                  <NavLink to="/ui-ux-experience">UI/UX Experience</NavLink>
                </li>
                <li className="mb-3">
                  <NavLink to="/digital-marketing">Digital Marketing</NavLink>
                </li>
                <li className="mb-3">
                  <NavLink to="/mobile-development">Mobile Development</NavLink>
                </li>
                <li className="mb-3">
                  <NavLink to="/social-media-marketing">
                    Social Media Marketing
                  </NavLink>
                </li>
                <li className="mb-3">
                  <NavLink to="/website-development">Website Development</NavLink>
                </li>
                <li>
                  <NavLink to="/business-strategy">Business Strategy</NavLink>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-6 mt-5 mt-md-0">
              <h5 className="mb-4">Useful Link</h5>
              <ul className="list-unstyled mb-0 footer-menu">
                <li className="mb-3">
                  <NavLink to="/about-us">About Us</NavLink>
                </li>
                <li className="mb-3">
                  <NavLink to="/contact">Contact</NavLink>
                </li>
                <li className="mb-3">
                  <NavLink to="/price-table">Price Table</NavLink>
                </li>
                <li className="mb-3">
                  <NavLink to="/blog-grid-3">Our Blogs</NavLink>
                </li>
                <li>
                  <NavLink to="/contact-us">Contact Us</NavLink>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
              <h5 className="mb-4">Newsletter</h5>
              <div className="subscribe-form">
                <form id="mc-form">
                  <input
                    type="email"
                    value={inputValue.email}
                    name="email"
                    className="email form-control"
                    id="mc-email"
                    placeholder="Enter your email address"
                    required
                    onChange={onInputChange}
                  />
                  <div className="primary-btn mt-3">
                    <input
                      className="subscribe-btn"
                      type="submit"
                      name="subscribe"
                      defaultValue="Subscribe Now"

                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="secondary-footer">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8 col-md-12">
              Copyright 2023 Starbiz Theme by
              <u>
                <a className="text-theme px-2" target="_black" href="https://skytouchinfotech.com/">
                  skytouchinfotech.com
                </a>
              </u>
              | All Rights Reserved
            </div>
            <div className="col-lg-4 col-md-8 text-lg-end mt-3 mt-lg-0">
              <ul className="list-inline p-0 m-0 social-icons footer-social">
                <li className="list-inline-item">
                  <NavLink to="/">
                    <i className="la la-facebook" />
                  </NavLink>
                </li>
                <li className="list-inline-item">
                  <NavLink to="/">
                    <i className="la la-dribbble" />
                  </NavLink>
                </li>
                <li className="list-inline-item">
                  <NavLink to="/">
                    <i className="la la-instagram" />
                  </NavLink>
                </li>
                <li className="list-inline-item">
                  <NavLink to="/">
                    <i className="la la-twitter" />
                  </NavLink>
                </li>
                <li className="list-inline-item">
                  <NavLink to="/">
                    <i className="la la-linkedin" />
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <div className={`${Visible ? 'scroll-top scol' : ''}`} >
      <NavLink class="smoothscroll" onClick={gototop}>
        <i class="las la-arrow-up">
        </i>
      </NavLink>
    </div>
  </>

  );
};

export default Footer;
