import React from 'react'
import pricingPlansApi from '../../api/pricingPlans/pricingPlansApi'
import { NavLink } from 'react-router-dom'

const PricingPlan = () => {
    return (
        <>
            <section>
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-lg-4">
                            <div class="theme-title ">
                                <h6>Pricing Plan</h6>
                                <h2>It Help You To Choose Prices To Maximize</h2>
                            </div>
                        </div>
                        <div class="col-12 col-lg-8 ps-lg-8 mt-5 mt-lg-0">
                            {pricingPlansApi.map((plan, index) => (
                                <PricingPlan2
                                    key={index}
                                    title={plan.title}
                                    price={plan.price}
                                    features={plan.features}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PricingPlan
const PricingPlan2 = ({ title, price, features }) => (
    <div class="price-table mb-5">
        <div>
            <div class="price-title">
                <h4 class="">{title}</h4>
            </div>
            <div class="price-value">
                <h2>{price} <span>/Mo</span></h2>
            </div>
            <NavLink class="primary-btn" >Get Started</NavLink>
        </div>
        <div class="price-list">
            <ul class="list-unstyled">
                {features.map((feature, index) => (
                    <li key={index}>
                        <i class="las la-check-circle"></i> {feature}
                    </li>
                ))}
            </ul>
        </div>
    </div>
);
