/*
 *  Main Menu Json
 */
const NavLink = [
  {
    menu_title: "Home",
    type: "subMenu",
    path: "/",
    icon: "home",
    child_routes: [
      {
        path: "/",
        menu_title: "Home One",
        icon: "arrow_right_alt",
        child_routes: null,
      },
      {
        path: "/home-two",
        menu_title: "Home Two",
        icon: "arrow_right_alt",
        child_routes: null,
      },
      {
        path: "/home-three",
        menu_title: "Home Three",
        icon: "arrow_right_alt",
        child_routes: null,
      },
    ],
  },
  {
    menu_title: "Pages",
    path: "/",
    mega: true,
    icon: "pages",
    type: "subMenu",
    child_routes: [
      {
        path: "/about-us",
        menu_title: "About Us",
        icon: "arrow_right_alt",
        child_routes: null,
      },
      {
        path: "/team",
        menu_title: "Team",
        icon: "arrow_right_alt",
        child_routes: null,
      },
      {
        path: "/team-single",
        menu_title: "Team Single",
        icon: "arrow_right_alt",
        child_routes: null,
      },
      {
        path: "/pricing",
        menu_title: "Pricing",
        icon: "arrow_right_alt",
        child_routes: null,
      },
      {
        path: "/faq",
        menu_title: "Faq",
        icon: "arrow_right_alt",
        child_routes: null,
      },
      {
        path: "/coming-soon",
        menu_title: "Coming Soon",
        icon: "arrow_right_alt",
        child_routes: null,
      },
      {
        path: "/error-404",
        menu_title: "Error 404",
        icon: "arrow_right_alt",
        child_routes: null,
      },
    ],
  },
  {
    menu_title: "Services",
    path: "/",
    mega: true,
    icon: "services",
    type: "subMenu",
    child_routes: [
      {
        path: "/ui-ux-experience",
        menu_title: "UI/UX Experience",
        icon: "arrow_right_alt",
        child_routes: null,
      },
      {
        path: "/digital-marketing",
        menu_title: "Digital Marketing",
        icon: "arrow_right_alt",
        child_routes: null,
      },
      {
        path: "/mobile-development",
        menu_title: "Mobile Development",
        icon: "arrow_right_alt",
        child_routes: null,
      },
      {
        path: "/social-media-marketing",
        menu_title: "Social Media Marketing",
        icon: "arrow_right_alt",
        child_routes: null,
      },
      {
        path: "/website-development",
        menu_title: "Website Development",
        icon: "arrow_right_alt",
        child_routes: null,
      },
      {
        path: "/business-strategy",
        menu_title: "Business Strategy",
        icon: "arrow_right_alt",
        child_routes: null,
      },
    ],
  },
  {
    menu_title: "Portfolio",
    type: "subMenu",
    path: "/",
    icon: "portfolio",
    child_routes: [
      {
        path: "/portfolio-style-one",
        menu_title: "Portfolio Style One",
        icon: "arrow_right_alt",
        child_routes: null,
      },
      {
        path: "/portfolio-style-two",
        menu_title: "Portfolio Style Two",
        icon: "arrow_right_alt",
        child_routes: null,
      },
      {
        path: "/portfolio-single",
        menu_title: "Portfolio Single",
        icon: "arrow_right_alt",
        child_routes: null,
      },
    ],
  },
  {
    menu_title: "Blogs",
    type: "subMenu",
    path: "/",
    icon: "blogs",
    child_routes: [
      {
        path: "/blog-style-one",
        menu_title: "Blog Style One",
        icon: "arrow_right_alt",
        child_routes: null,
      },
      {
        path: "/blog-left-sidebar",
        menu_title: "Blog Left Sidebar",
        icon: "arrow_right_alt",
        child_routes: null,
      },
      {
        path: "/blog-right-sidebar",
        menu_title: "Blog Right Sidebar",
        icon: "arrow_right_alt",
        child_routes: null,
      },
      {
        path: "/blog-single",
        menu_title: "Blog Single",
        icon: "arrow_right_alt",
        child_routes: null,
      },
    ],
  },
];
export default NavLink;
