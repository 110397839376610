/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import navLinks from '../../api/NavLinks';
import {
  Nav,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Collapse,
  UncontrolledDropdown,
} from 'reactstrap';
import { FiChevronDown } from 'react-icons/fi'
import { NavLink } from 'react-router-dom';


const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [visible, setVisible] = useState(false);
  const [loader, setLoader] = useState(true);
  const [isSearch, setSearch] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isCartview, setIsCartview] = useState(false);
  const [inputValue, setInputValue] = useState({
    search: "",
  })

  const onInputChange = (evt) => {
    const { name, value } = evt.target;
    setInputValue((prev) => ({ ...prev, [name]: value }))
  }
  const wrapperRef = useRef(null);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const [openMenus, setOpenMenus] = useState([]);

  const toggleMenu = (index) => {
    if (openMenus.includes(index)) {
      setOpenMenus(openMenus.filter((item) => item !== index));
    } else {
      setOpenMenus([...openMenus, index]);
    }
  };
  const closeAllMenus = () => {
    setOpenMenus([]);
  };
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsCartview(false);
    }
  };
  const handleScroll = () => {
    var scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    if (scrollTop > 100) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  useEffect(() => {
    if (loader) {
      const timeout = setTimeout(() => {
        setLoader(false);
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [loader]);

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    }
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension);
    return (() => {
      window.removeEventListener('resize', updateDimension);
    })
  }, [screenSize])
  const search = () => {
    setSearch((pre) => !pre)
  }

  return (
    <>
      <header id="site-header" className="header">
        <div className="header-top">
          <div className="container-fluid">
            <div className="row">
              <div className="col d-flex align-items-center justify-content-between">
                <div className="topbar-link ">
                  <ul className="list-inline d-flex flex-row">
                    <li>
                      <i className="flaticon flaticon-calendar" />
                      <span>Monday - Friday 10:00 to 6:00</span>
                    </li>
                    <li>
                      <i className="flaticon flaticon-location" />
                      <span>5th Street, 21st Floor, New York, USA</span>
                    </li>
                    <li>
                      <i className="flaticon-chat" />
                      <Link to="mailto:info@skytouchinfotech.com" className="text-decoration-none">info@skytouchinfotech.com</Link>
                    </li>
                  </ul>
                </div>
                <div className="d-flex align-items-center">
                  <div class="search-icon me-3" onClick={() => { search() }}>
                    <Link id="search" >
                      <i class="las la-search"></i>
                    </Link>
                  </div>
                  <div className="social-icons">
                    <ul className="list-inline">
                      <li>
                        <Link to="/">
                          <i className="lab la-facebook-f" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <i className="lab la-twitter" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <i className="lab la-instagram" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <i className="lab la-linkedin-in" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="header-wrap" className={`${visible ? 'fixed-header ' : ''}`}>
          <div className="container-fluid ">
            <div className="row">

              <div className="col">
                <Nav className="navbar navbar-expand-lg d-flex justify-content-between ">
                  <Link className="navbar-brand logo" to="/">
                    <img className="img-fluid" src={require("../../assets/images/logo.png")} alt="Img1" />
                  </Link>

                  {(screenSize.width < 991) && toggle ? <button onClick={toggle} className="navbar-toggler ht-toggler " type="button" >
                    <svg width={100} height={100} viewBox="0 0 100 100">
                      <path className="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
                      <path className="line line2" d="M 20,50 H 80" />
                      <path className="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
                    </svg>
                  </button> : <button class="navbar-toggler ht-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-expanded="true" aria-label="Toggle navigation">
                    <svg width="100" height="100" viewBox="0 0 100 100">
                      <path class="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"></path>
                      <path class="line line2" d="M 20,50 H 80"></path>
                      <path class="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"></path>
                    </svg>
                  </button>}
                  {(isOpen || (screenSize.width > 991)) && <Collapse className='collapse navbar-collapse  justify-content-center d-block d-lg-none d-xl-block' navbar>
                    {navLinks.map((navLink, index) => (
                      <Nav key={index} className=" navbar-nav  m-0" navbar>
                        <UncontrolledDropdown nav inNavbar>
                          <DropdownToggle nav caret className='m-0'>
                            {navLink.menu_title}<FiChevronDown />
                          </DropdownToggle>
                          <DropdownMenu tag="ul">
                            {navLink.child_routes.map((subNavLink, index) => (
                              <DropdownItem tag="li" className='p-0'>
                                <DropdownItem key={index} className='dropdown-item-style ' tag={NavLink} to={subNavLink.path}>
                                  {subNavLink.menu_title}
                                </DropdownItem>
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </Nav>
                    ))}
                    <ul className="navbar-nav ">
                      <li className="nav-item">
                        <Link className="nav-link" to="/contact-us">Contact</Link>
                      </li>
                    </ul>
                  </Collapse>}

                  <div className="right-nav align-items-center d-flex justify-content-end">
                    <div className="header-number">
                      <i className="flaticon-phone-call" />
                      <div>
                        <span>Call Us:</span>
                        <Link to="tel:+91-234-567-8900" className="text-decoration-none text-reset">+91-234-567-8900</Link>
                      </div>
                    </div>
                    <Link className="primary-btn text-decoration-none" to="/">Get It Now </Link>
                  </div>

                </Nav>
              </div>

            </div>
          </div>
        </div>
      </header>
      <div className={`search-input ${isSearch ? "d-block" : "d-none"}`} id="search-input-box">
        <div className="search-inner-box">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <form role="search" id="search-form" className="search-form d-flex justify-content-between search-inner">
                  <label>
                    <input type="search" className="search-field" placeholder="Search Here" value={inputValue.search} name="search" onChange={onInputChange} />
                  </label>
                  <button type="submit" className="search-submit" >
                    <i className="las la-search"></i>
                  </button>
                  <span className="las la-times close-search" id="close-search" title="Close Search" onClick={search}></span>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default Header