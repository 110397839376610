import React from 'react'

const BannerAdd = ({ addName, addName2, addName3, addName4 }) => {
    return (
        <>
            <section>
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="marquee-wrap">
                                <div className="marquee-text">{addName}
                                    <i className="las la-asterisk" />{addName2}
                                    <i className="las la-asterisk" />{addName3}
                                    <i className="las la-asterisk" />{addName4}
                                    <i className="las la-asterisk" />{addName}
                                    <i className="las la-asterisk" />{addName2}
                                    <i className="las la-asterisk" />{addName3}
                                    <i className="las la-asterisk" />{addName4}
                                    <i className="las la-asterisk" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default BannerAdd
