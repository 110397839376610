import React from 'react'
import { NavLink } from 'react-router-dom'

const ErrorPage = () => {
    return (
        <div class="page-content">
            <section className="p-0 vh-100">
                <div className="container h-100">
                    <div className="row justify-content-center text-center h-100 align-items-center">
                        <div className="col-lg-7">
                            <img className="img-fluid mb-5" src={require("../../assets/images/404.png")} alt="Img1" />
                            <div>
                                <h2>Oops! Page Not Found</h2>
                                <h6 className="mb-5">You’re either misspelling the URL or requesting a page that's no longer here.</h6>
                                <NavLink className="primary-btn" to="/"><span>Back To Home Page</span></NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ErrorPage
