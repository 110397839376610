const portfolioApi =[ {
    icon: "flaticon-expert",
    title: "Created By:",
    subTitle: "info@skytouchinfotech.com",
  },
  {
    icon: "flaticon-project-management",
    title: "Project Completed:",
    subTitle: "March 23, 2023",
  },
  {
    icon: "flaticon-calendar",
    title: "Duration:",
    subTitle: "One Month",
  },
  {
    icon: "flaticon-tag",
    title: "Category:",
    subTitle: "Business Agency",
  },
  {
    icon: "flaticon-location",
    title: "Location:",
    subTitle: "5th Street, 21st Floor, New York, USA",
  },]
export default portfolioApi;