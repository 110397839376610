import portfolio1 from "../../assets/images/portfolio/01.jpg";
import portfolio2 from "../../assets/images/portfolio/02.jpg";
import portfolio3 from "../../assets/images/portfolio/03.jpg";
import portfolio4 from "../../assets/images/portfolio/04.jpg";
import portfolio5 from "../../assets/images/portfolio/05.jpg";

const ProjectApi = [
    {
        id: 1,
        title: "Development",
        subTitle: "Web Development",
        imageSrc: portfolio1,
        titleLink: "",
        subTitleLink: "",
    },
    {
        id: 2,
        title: "Development",
        subTitle: "SEO Optimization",
        imageSrc: portfolio2,
        titleLink: "/",
        subTitleLink: "/",
    },
    {
        id: 3,
        title: "Technology",
        subTitle: "SEO Marketing",
        imageSrc: portfolio3,
        titleLink: "/",
        subTitleLink: "/",
    },
    {
        id: 4,
        title: "Branding",
        subTitle: "Smart Branding",
        imageSrc: portfolio4,
        titleLink: "/",
        subTitleLink: "/",
    },
    {
        id: 5,
        title: "Branding",
        subTitle: "Smart Branding",
        imageSrc: portfolio5,
        titleLink: "/",
        subTitleLink: "/",
    },
];
export default ProjectApi;
