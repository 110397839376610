import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import CarouseApi4 from '../../api/CarouselApi/CarouseApi4';

const Carousel4 = ({ bgcolor }) => {
    return (
        <section className={`pt-0 text-center ${bgcolor} `}>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <OwlCarousel
                            className="owl-carousel no-pb"
                            center={true}
                            dots={false}
                            items={5}
                            loop={true}
                            responsive={{
                                0: { items: 1 },
                                576: { items: 1 },
                                768: { items: 3 },
                                992: { items: 4 },
                                1200: { items: 5 },
                            }}
                            autoplay={true}
                        >
                            {CarouseApi4.map((logo, index) => (
                                <div className="item " key={index} >
                                    <div className="client-logo ">
                                        <img src={logo.imageSrc} className="img-fluid d-inline marquee-image" alt={`Client ${index}`} />
                                    </div>
                                </div>
                            ))}
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Carousel4