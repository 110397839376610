import React from 'react'
import large3 from '../../assets/images/services/large/03.jpg'
import HeroSection4 from '../../Components/heroSection/HeroSection4'
import BlogSection2 from '../../Components/blogSection/BlogSection2'

const MobileDevelopment = () => {
  return (
    <>
      <HeroSection4 name={"UI/UX Experience"} name2={"Home"} />
      <BlogSection2 bgImage={large3} selected={3} />
    </>
  )
}

export default MobileDevelopment
