import React from 'react'
import bg3 from "../../assets/images/bg/03.jpg"
import { NavLink } from 'react-router-dom'


const HeroSection4 = ({ name, name2 }) => {
    return (
        <>
            <section className="page-title" style={{ backgroundImage: `url(${bg3})` }}>
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-lg-8">
                            <div className="theme-breadcrumb-box">
                                <h1>{name}</h1>
                                <nav aria-label="breadcrumb" className="page-breadcrumb ">
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item"><NavLink to="/"><i className="las la-home me-1 " />{name2}</NavLink>
                                        </li>
                                        <li className="breadcrumb-item  ps-0" aria-current="page">
                                            <NavLink className='c-breadcrumb'>{name}</NavLink></li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                    {/* / .row */}
                </div>
                {/* / .container */}
            </section>

        </>
    )
}

export default HeroSection4
