const PriceListApi = [
    {
        icon: "check-circle",
        details: "Upto 5 design revisions",
    },
    {
        icon: "check-circle",
        details: "Unlimited Site licenses",
    },
    {
        icon: "check-circle",
        details: "WordPress Website",
    },
    {
        icon: "check-circle",
        details: "50gb Web Storage",
    },
    {
        icon: "check-circle",
        details: "24/7 Customer Support",
    },
]

export default PriceListApi;