const AboutApi3 = [
  {
    id: 1,
    icon: 'flaticon-statistics',
    title: 'Website Development',
    description: 'There are many variations variations of passages of Lorem Ipsum available.',
  },
  {
    id: 2,
    icon: 'flaticon-idea',
    title: 'Creative Design',
    description: 'There are many variations variations of passages of Lorem Ipsum available.',
  },
  {
    id: 3,
    icon: 'flaticon-rocket-launch',
    title: 'SEO Friendly',
    description: 'There are many variations variations of passages of Lorem Ipsum available.',
  },
  {
    id: 4,
    icon: 'flaticon-announcement',
    title: '24/7 Support',
    description: 'There are many variations variations of passages of Lorem Ipsum available.',
  },
];
export default AboutApi3