import React, { useState } from "react";
import About1 from "../../assets/images/about/02.jpg";
import AgencySvg from "../svg/Agency";
import OpneModal from "../modal/OpneModal";

const VideoSection = () => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <>
      <section className="about-section">
        <div className="container">
          <div className="row">
            <div className="col-12 position-relative">
              <img
                src={About1}
                className="img-fluid rounded img-filter"
                alt="Img1"
              />
              <div className="round-text cursor-pointer" onClick={openModal}>
                <AgencySvg label={" Watch Video Now • Watch Video Now •"} icon={"las la-play"} />
              </div>
            </div>
          </div>
        </div>
        <OpneModal isOpen={isOpen} closeModal={closeModal} />
      </section>
    </>
  );
};

export default VideoSection;
