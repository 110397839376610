import React from 'react'
import HeroSection4 from '../../Components/heroSection/HeroSection4'
import Carousel from '../../Components/reactcarouselSection/Carousel'

const Portfolio_style_one = () => {
    return (
        <>
            <HeroSection4 name={"Portfolio Style One"} name2={"Home"} />
            <div class="page-content">
                <Carousel margin={"mt-10"} />
            </div>

        </>
    )
}

export default Portfolio_style_one
