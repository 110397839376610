import React from 'react'
import Information1APi from '../../api/Information1APi'

const Information1 = () => {
    return (
        <>
            <section>
                <div className="container">
                    <div className="row">
                        {Information1APi.map((item, index) => {
                            return (
                                <div key={index} className={`col-lg-3 col-md-6${index > 1 ? ' mt-5 mt-lg-0' : ''}`}>
                                    <div className="featured-item style-2">
                                        <div className="featured-icon">
                                            <i className={`flaticon ${item.icon}`} />
                                            <span className="feature-number">0{item.id}</span>
                                        </div>
                                        <div className="featured-desc">
                                            <div className="featured-title">
                                                <h5>{item.title}</h5>
                                            </div>
                                            <p>There are many variations variations of passages of Lorem Ipsum available.</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Information1
