
import portfolio6 from '../../assets/images/portfolio/06.jpg'
import portfolio7 from '../../assets/images/portfolio/07.jpg'
import portfolio8 from '../../assets/images/portfolio/08.jpg'
import portfolio9 from '../../assets/images/portfolio/09.jpg'
import portfolio10 from '../../assets/images/portfolio/10.jpg'


const ProjectApi2 = [
    {
      imageSrc: portfolio6,
      category: 'Development',
      title: 'Web Development',
      link: '/web-development',
    },
    {
      imageSrc: portfolio7,
      category: 'Development',
      title: 'SEO Optimization',
      link: '/seo-optimization',
    },
    {
      imageSrc: portfolio8,
      category: 'Technology',
      title: 'SEO Marketing',
      link: '/seo-marketing',
    },
    {
      imageSrc: portfolio9,
      category: 'Branding',
      title: 'Smart Branding',
      link: '/smart-branding',
    },
    {
      imageSrc: portfolio10,
      category: 'Branding',
      title: 'Smart Branding',
      link: '/smart-branding',
    },
  ];

export default ProjectApi2