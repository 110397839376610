export const SideMenu = [
    {
        id: 1,
        title: "UI/UX Experience",
        link: "/ui-ux-experience",
    },
    {
        id: 2,
        title: "Digital Marketing",
        link: "/digital-marketing",
    },
    {
        id: 3,
        title: "Mobile Development",
        link: "/mobile-development",
    },
    {
        id: 4,
        title: "Social Media Marketing",
        link: "/social-media-marketing",
    },
    {
        id: 5,
        title: "Website Development",
        link: "/website-development",
    },
    {
        id: 6,
        title: "Business Strategy",
        link: "/business-strategy",
    },
]