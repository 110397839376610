
import service6 from '../../assets/images/services/06.jpg'
import service7 from '../../assets/images/services/07.jpg'
import service8 from '../../assets/images/services/08.jpg'
import service9 from '../../assets/images/services/09.jpg'
import service10 from '../../assets/images/services/10.jpg'

const CarouseApi3 = [
    {
        title: "Mobile Development",
        imageSrc: service6,
        desc: "Design professionals, there's a bit of controversy surrounding the filler text",
        iconClass: "flaticon-mobile-application",
        link: "/mobile-development",
    },
    {
        title: "Business Strategy",
        imageSrc: service7,
        desc: "Design professionals, theres a bit of controversy surrounding the filler text",
        iconClass: "flaticon-boost",
        link: "/business-strategy",
    },
    {
        title: "Digital Marketing",
        imageSrc: service8,
        desc: "Design professionals, there's a bit of controversy surrounding the filler text",
        iconClass: "flaticon-content-marketing",
        link: "/digital-marketing",
    },
    {
        title: "Social Media Marketing",
        imageSrc: service9,
        desc: "Design professionals, there's a bit of controversy surrounding the filler text",
        iconClass: "flaticon-social-media",
        link: "social-media-marketing",
    },
    {
        title: "Website Development",
        imageSrc: service10,
        desc: "Design professionals, there's a bit of controversy surrounding the filler text",
        iconClass: "flaticon-software-development",
        link: "website-development",
    },
];
export default CarouseApi3;