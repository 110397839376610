import React from 'react'
import HeroSection4 from '../../Components/heroSection/HeroSection4'
import TeamMember from '../../Components/teamMember/TeamMember'

const Team = () => {
  return (
    <>
    <HeroSection4 name={"Our Team"} name2={"Home"} />
    <div class="page-content">
        <TeamMember />
    </div>
      
    </>
  )
}

export default Team
