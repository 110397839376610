import Services1 from "../../assets/images/services/01.jpg";
import Services2 from "../../assets/images/services/02.jpg";
import Services3 from "../../assets/images/services/03.jpg";
import Services4 from "../../assets/images/services/04.jpg";
import Services5 from "../../assets/images/services/05.jpg";
const ServicesApi1 = [
  {
    id: 1,
    title: "Mobile Development",
    description: "Design professionals, there’s a bit of controversy surrounding the filler text",
    img: Services1,
    path:"/mobile-development"
  },
  {
    id: 2,
    title: "Business Strategy",
    description: "Design professionals, there’s a bit of controversy surrounding the filler text",
    img: Services2,
    path:"/business-strategy"
  },
  {
    id: 3,
    title: "Digital Marketing",
    description: "Design professionals, there’s a bit of controversy surrounding the filler text",
    img: Services3,
    path:"/digital-marketing"
  },
  {
    id: 4,
    title: "Social Media Marketing",
    description: "Design professionals, there’s a bit of controversy surrounding the filler text",
    img: Services4,
    path:"/social-media-marketing"
  },
  {
    id: 5,
    title: "Website Development",
    description: "Design professionals, there’s a bit of controversy surrounding the filler text",
    img: Services5,
    path:"/website-development"
  },
];
export default ServicesApi1;
