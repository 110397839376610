import React from 'react'
import CommentsApi from '../../api/CommentsApi '
import Sidebar from '../sideBar/Sidebar'
import BlogLeftApi from '../../api/BlogApi/BlogLeftApi'
import { NavLink } from 'react-router-dom'

const BlogSingle = () => {
    const myData = JSON.parse(localStorage.getItem("blog"));
    const myData2 = JSON.parse(localStorage.getItem("blogleft"))
    console.log("🚀 ~ file: BlogSingle.js:10 ~ BlogSingle ~ mydata2:", myData2)
    return (
        <>
            <section className="theme-blogs">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-9 pe-lg-5 mb-6 mb-lg-0">
                            <div className="post-card shadow-none">
                                {myData ? <img className="img-fluid" src={myData} alt="Image1" />
                                    :
                                    <img className="img-fluid" src={require("../../assets/images/blog/01.jpg")} alt="Image1" />}
                                <div className="post-desc">
                                    <div className="post-meta">
                                        <ul className="list-inline post-bottom">
                                            <li className="list-inline-item">
                                                <i className="las la-user-circle" />
                                                <span className="byline">
                                                    <span className="author vcard">
                                                        <NavLink to="/">pentair23</NavLink>
                                                    </span>
                                                </span>
                                            </li>
                                            <li className="list-inline-item">
                                                <i className="las la-calendar-alt" />
                                                <span> February 24, 2023 </span>
                                            </li>
                                            <li className="list-inline-item">
                                                <i className="las la-comments" />3 Comments
                                            </li>
                                            <li className="list-inline-item">
                                                <i className="las la-folder-open" />
                                                <span className="cat-links">
                                                    <NavLink to="/" rel="category tag">Mechanical</NavLink>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable. If you are going to use NavLink passage of Lorem Ipsum. You need to be sure there isn’t anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend toitrrepeat predefined chunks.</p>
                                    <p>Highly at nisi nam vestibulum ut eget vitae sed. Potenti aliquam feugiat proin facilisi calibrated. for us if we carry yesterday’s burden over again today, and then add the burden of the morrow before we are required to bear it All these digital elements and projects aim to enhance</p>
                                    <blockquote className="theme-blockquote my-7">
                                        <p>"Wow what great service, I love it! You won’t regret it. It’s incredible. If you aren’t sure, always go for Affirm. Affirm is worth much more than I paid."</p>
                                        <span>- Carmela Jensen</span>
                                    </blockquote>
                                    <p>We can easily manage if we will only take, each day, the burden appointed to it. But the load will be too heavy for us if we carry yesterday’s burden over again today, and then add the burden of the morrow before we are required to bear it All these digital elements and projects aim to enhance</p>
                                    <div className="row mb-5">
                                        {BlogLeftApi.slice(0, 2).map((item, i) => (<div key={i} className="col-md-6">
                                            <img className="img-fluid" src={item.imageSrc} alt="Image1" />
                                        </div>))}
                                    </div>
                                    <p>Websites your audience visits. These ads typically include NavLink visual element with copy and link to NavLink landing page where users may convert into leads or customers. Using online advertising platforms such as Google Ads, you can target your ads to users whose interests, demographics, or online activity matches those of your target audience. </p>
                                    <div className="theme-tags">
                                        <span className="tags-links">Tags: <NavLink to="/" rel="tag">Agency</NavLink>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {/* ... (rest of the post card content) */}
                            <div className="post-comment">
                                <ul className="list-unstyled">
                                    {CommentsApi.map((comment, index) => (
                                        <li className="comment" key={index}>
                                            <article className="comment-body">
                                                <footer className="comment-meta">
                                                    <div className="comment-author vcard">
                                                        <img className='avatar img-fluid' src={require("../../assets/images/thumbnail/01.jpg")} alt="" />
                                                        <b className='fn'>
                                                            <NavLink to="/">{comment.author}</NavLink>
                                                        </b>
                                                    </div>
                                                    <div className="comment-metadata">
                                                        <NavLink to="/">{comment.date}</NavLink>
                                                    </div>
                                                </footer>
                                                <div className="comment-content">
                                                    <p>{comment.content}</p>
                                                </div>
                                                <div className="reply mt-5">
                                                    <NavLink className="comment-reply-link" to="/">Reply</NavLink>
                                                </div>
                                            </article>
                                        </li>
                                    ))}
                                </ul>
                                {/* ... (comment submission form) */}
                            </div>
                        </div>
                        <div className="col-lg-3 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default BlogSingle
