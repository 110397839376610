import React from 'react'
import AgencySvg from '../svg/Agency'
import { NavLink } from 'react-router-dom'

const HeroSection3 = () => {
    return (
        <>
            <section>
                <div className="container-fluid px-lg-10">
                    <div className="row align-items-center">
                        <div className="col-lg-3 col-12 text-center">
                            <div className="round-text">
                                <AgencySvg label={"Since 1980 • Since 1980 • Since 1980 •"} icon={"flaticon-brand"} />
                            </div>
                            <img className="img-fluid rounded-5" src={require("../../assets/images/about/05.jpg")} alt="Img1" />
                        </div>
                        <div className="col-lg-5 col-12 px-lg-10 my-5 my-lg-0">
                            <h1>Creative Agency With Solid Design</h1>
                            <p className="my-5">A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences</p>
                            <NavLink to="/contact" className="primary-btn">Let's Talk With Us</NavLink>
                            <div className="text-end">
                                <div className="round-text">
                                    <AgencySvg label={"Creative Agency • Business Agency •"} icon={"flaticon-brand"} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-12">
                            <img className="img-fluid rounded-5" src={require("../../assets/images/about/06.jpg")} alt="Img2" />
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default HeroSection3
