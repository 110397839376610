import React from 'react'
import portfolioApi from '../../api/portfolioApi/portfolioApi'
import { HowWeWorks } from '../../api/HowWeWorks/HowWeWorks'

const Portfolio = () => {
    const myData = JSON.parse(localStorage.getItem("portfolio"));
    console.log("🚀 ~ file: Portfolio.js:7 ~ Portfolio ~ myData:", myData)
    return (
        <>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="portfolio-image ">
                                {myData ? <img className="img-fluid" src={myData} alt="Img1" /> :
                                    <img className="img-fluid" src={require("../../assets/images/portfolio/01.jpg")} alt="Img1" />}

                            </div>
                        </div>
                    </div>
                    <div className="row mt-8">
                        <div className="col-lg-8 col-12 pe-lg-10">
                            <h3>Overview </h3>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantitum dolore mque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto. Beatae vitae dict eaque ipsa quae ab illo inventore veritatis et quasi architecto. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                            <div className="row mt-4 align-items-center">
                                <div className="col-lg-6 ">
                                    <img className="img-fluid" src={require("../../assets/images/portfolio/05.jpg")} alt="Img1" />
                                </div>
                                <div className="col-lg-6 mt-5 mt-lg-0">
                                    <ul className="list-unstyled list-icon">
                                        {HowWeWorks.slice(0, 5).map((item, index) => {
                                            return (<li key={index}>
                                                <i className="las la-check-circle" />{item.list}
                                            </li>)
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-12 border-start ps-5 border-light">
                            <div>
                                {portfolioApi.map((item, index) => (
                                    <div className="portfolio-meta-list mb-4" key={index}>
                                        <div className="portfolio-meta-icon">
                                            <i aria-hidden="true" className={`flaticon ${item.icon}`} />
                                        </div>
                                        <div className="portfolio-meta-desc">
                                            <span className="portfolio-meta-title">{item.title}</span>
                                            <span className="portfolio-meta-sub-title">{item.subTitle}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Portfolio
