/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'

const ProgressBar = ({ title, value }) => {
    const [progress, setProgress] = useState(0); // Current progress value (between 0 and 100)
    useEffect(() => {
        if (!isNaN(progress)) {
            setProgress(Math.min(100, Math.max(0, value))); // Ensure progress stays between 0 and 100
        }
    }, [value]);
    return (
        <div className="theme-progress-bar mb-4">
            <h4>{title}</h4>
            <div className="progress" >
                <div className="progress-bar" style={{ width: `${progress}%` }}>
                    <div className="progress-parcent">
                        <span>{`${progress}%`}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProgressBar