const AboutApi7 = [
    {
        icon: 'flaticon flaticon-statistics',
        title: 'Marketing',
        description: 'Spend less time writing a plan and more time building your business.',
    },
    {
        icon: 'flaticon flaticon-idea',
        title: 'Creative Design',
        description: 'Spend less time writing a plan and more time building your business.',
    },
    {
        icon: 'flaticon flaticon-rocket-launch',
        title: 'SEO Friendly',
        description: 'Spend less time writing a plan and more time building your business.',
    },
    {
        icon: 'flaticon flaticon-announcement',
        title: '24/7 Support',
        description: 'Spend less time writing a plan and more time building your business.',
    },
];
export default AboutApi7