import React from 'react'

const AgencySvg = ({ label, icon }) => {
    return (
        <>
            <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="100%"
                height="100%"
                viewBox="0 0 180 180"
                enableBackground="new 0 0 180 180"
                xmlSpace="preserve"
            >
                <defs>
                    <path
                        id="circlePath"
                        d="M 90, 90 m -60, 0 a 60,60 0 0,1 120,0 a 60,60 0 0,1 -120,0 "
                    />
                </defs>
                <circle cx="90" cy="90" r="75" fill="none" />
                <g>
                    <use xlinkHref="#circlePath" fill="none" />
                    <text>
                        <textPath xlinkHref="#circlePath">
                            {label}
                        </textPath>
                    </text>
                </g>
            </svg>
            <div className="text-btn-icon">
                <i
                    aria-hidden="true"
                    className={`flaticon ${icon}`}
                ></i>
            </div>
        </>
    )
}

export default AgencySvg