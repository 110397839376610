import React from "react";

const BannerAdd2 = ({ name, name2, name3 }) => {
  return (
    <>
      <section className="py-0 pb-5">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="marquee-title">
                <div className="marquee-text">
                  {name} <i className="las la-asterisk" />
                  {name2} <i className="las la-asterisk" />
                  {name3} <i className="las la-asterisk" />
                  {name} <i className="las la-asterisk" />
                  {name2} <i className="las la-asterisk" />
                  {name3} <i className="las la-asterisk" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BannerAdd2;
