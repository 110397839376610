 const AwardApi = [
    {
        awardNum: "01",
        awardTitle: "CSS Design Awards",
        awardProject: "Starbiz",
        awardYears: "2023"
    },
    {
        awardNum: "02",
        awardTitle: "Google awards",
        awardProject: "Starbiz",
        awardYears: "2022"
    },
    {
        awardNum: "03",
        awardTitle: "Rise Technology",
        awardProject: "Starbiz",
        awardYears: "2021"
    },
    {
        awardNum: "04",
        awardTitle: "UI/UX Design Of The Month",
        awardProject: "Starbiz",
        awardYears: "2020"
    },
    {
        awardNum: "05",
        awardTitle: "Technology Innovation",
        awardProject: "Starbiz",
        awardYears: "2019"
    }
];
export default AwardApi