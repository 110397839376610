import React from 'react'
import HeroSection3 from '../../Components/heroSection/HeroSection3'
import Carousel4 from '../../Components/reactcarouselSection/Carousel4'
import AboutSection6 from '../../Components/aboutSection/AboutSection6'
import PurchaseStarbiz from '../../Components/common/PurchaseStarbiz'
import Services3 from '../../Components/servicesSection/Services3'
import AboutSection from '../../Components/aboutSection/AboutSection'
import about1 from "../../assets/images/about/07.jpg"
import BannerAdd from '../../Components/bannerAdd/BannerAdd'
import Counter from '../../Components/counter/Counter'
import Project2 from '../../Components/projectSection/Project2'
import Carousel2 from '../../Components/reactcarouselSection/Carousel2'
import AboutSection7 from '../../Components/aboutSection/AboutSection7'
import BannerAdd2 from '../../Components/bannerAdd/BannerAdd2'
import Award from '../../Components/AwardSection/Award'
import ContactSection from '../../Components/contactSection/ContactSection'
import BlogSection from '../../Components/blogSection/BlogSection'

const HomeThree = () => {
  return (
    <>
      <HeroSection3 />
      <div class="page-content">
        <Carousel4 />
        <AboutSection6 />
        <PurchaseStarbiz />
        <AboutSection img={about1} imgStyle={"-circle"} />
        <BannerAdd addName={"Creative Agency"} addName2={"Business Agency"} addName3={"Design Agency"} addName4={"Marketing Agency "} />
        <Services3 />
        <Counter />
        <Project2 />
        <Carousel2 bgColor={"bg-dark-2"} />
        <AboutSection7 />
        <BannerAdd2 name={"Awards Winning"} name2={"Awards Winning"} name3={"Awards Winning"} />
        <Award />
        <ContactSection />
        <BlogSection  />
      </div>
    </>
  )
}

export default HomeThree
