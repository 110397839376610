import React from 'react'
import HeroSection4 from '../../Components/heroSection/HeroSection4'
import ContactUsSection from '../../Components/contactUs/ContactUsSection'

const ContactUs = () => {
    return (
        <>
            <HeroSection4 name={"Contact Us"} name2={"Home"} />
            <div class="page-content">
                <ContactUsSection />
            </div>

        </>
    )
}

export default ContactUs
