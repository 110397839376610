import React from 'react'
import HeroSection4 from '../../Components/heroSection/HeroSection4'
import TeamDetails from '../../Components/teamDetails/TeamDetails'

const TeamSingle = () => {
    return (
        <>
            <HeroSection4 name={"Team Single"} name2={"Home"} />
            <div class="page-content">
                <TeamDetails />
            </div>
        </>
    )
}

export default TeamSingle
