import React from 'react'
import HeroSection4 from '../../Components/heroSection/HeroSection4'
import BlogSection from '../../Components/blogSection/BlogSection'

const Blog_Style_1 = () => {
    return (
        <>
            <HeroSection4 name={"Blog Style One"} name2={"Home"} />
            <div class="page-content">
                <BlogSection boolean={true} />
                <BlogSection boolean={true} />
            </div>

        </>
    )
}

export default Blog_Style_1
