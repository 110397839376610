import React from 'react'
import BlogApi from '../../api/BlogApi'
import { useNavigate } from 'react-router-dom'
import { NavLink } from 'react-router-dom';

const BlogSection = ({ boolean }) => {
  const navigate = useNavigate();
  const Blognavigate = (item, index) => {
    navigate(`/blog-single/${index + 1}`)
    localStorage.setItem('blog', JSON.stringify(item.image));
    console.log("🚀 ~ file: BlogSection.js:11 ~ Blognavigate ~ item.img:", item.image)
  }
  return (<>
    <section className={`pt-0 ${boolean ? "p-0" : "pt-5"}`}>
      <div className="container">
        {boolean ? "" : <div className="row mb-5 align-items-center">
          <div className="col-lg-6">
            <div className="theme-title">
              <h6>Blogs</h6>
              <h2>Explore Our Latest News & Article</h2>
            </div>
          </div>
          <div className="col-lg-6 text-end">
            <NavLink to={"/"} className="white-btn">View More </NavLink>
          </div>
        </div>}
        <div className="row gx-lg-5">
          {BlogApi.map((blog, index) => (
            <div className="col-lg-4 col-md-6 mt-5 ju" key={index}>
              <div className="post-card style-1">
                {boolean ? (<div><div>
                  <div className="post-description">
                    <ul className="list-inline post-bottom mb-0">
                      <li className="list-inline-item">
                        <span>{blog.date}</span>
                      </li>
                      <li className="list-inline-item">
                        <span className="cat-links">
                          <NavLink rel="category tag">{blog.category}</NavLink>
                        </span>
                      </li>
                    </ul>
                    <div className="post-title">
                      <h4>
                        <div className='cursor-pointer' onClick={() => Blognavigate(blog, index)} >{blog.title}</div>
                      </h4>
                    </div>
                  </div>
                  <div className="post-image">
                    <img className="img-fluid" src={blog.image} alt="Image1" />
                  </div>
                </div>
                </div>) : (<div><div className="post-image">
                  <img className="img-fluid" src={blog.image} alt="Image1" />
                </div>
                  <div className="post-description">
                    <ul className="list-inline post-bottom mb-0">
                      <li className="list-inline-item">
                        <i className="flaticon-calendar"></i>
                        <span>{blog.date}</span>
                      </li>
                      <li className="list-inline-item">
                        <i className="flaticon-tag"></i>
                        <span className="cat-links">
                          <NavLink rel="category tag">{blog.category}</NavLink>
                        </span>
                      </li>
                    </ul>
                    <div className="post-title">
                      <h4>
                        <div className='cursor-pointer' onClick={() => Blognavigate(blog, index)}>{blog.title}</div>
                      </h4>
                    </div>
                  </div>
                </div>)}

              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  </>
  )
}

export default BlogSection
