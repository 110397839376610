import React, { useEffect, useState } from 'react'
import HeroSection2 from '../../Components/heroSection/HeroSection2'
import AboutSection3 from '../../Components/aboutSection/AboutSection3'
import Services2 from '../../Components/servicesSection/Services2'
import PurchaseStarbiz from '../../Components/common/PurchaseStarbiz'
import AboutSectopn4 from '../../Components/aboutSection/AboutSection4'
import BannerAdd2 from '../../Components/bannerAdd/BannerAdd2'
import Project1 from '../../Components/projectSection/Project1'
import Carousel4 from '../../Components/reactcarouselSection/Carousel4'
import TeamMember from '../../Components/teamMember/TeamMember'
import Carousel2 from '../../Components/reactcarouselSection/Carousel2'
import bg1 from '../../assets/images/bg/01.jpg'
import PricingSection from '../../Components/pricingSection/PricingSection'
import Counter from '../../Components/counter/Counter'
import BlogSection from '../../Components/blogSection/BlogSection'
import ContactSection from '../../Components/contactSection/ContactSection'

const HomeTwo = () => {
    const [limit, setLimit] = useState()
    const handleInfinitescroll = async () => {
        // console.log("scrollTop" + document.documentElement.scrollTop); 
        try {
            if (document.documentElement.scrollTop >= 4500) {
                setLimit(true)
            }
            else if (document.documentElement.scrollTop === 0) { setLimit(false) }
        } catch (error) {
            console.log(error);
        }

    }
    useEffect(() => { window.addEventListener('scroll', handleInfinitescroll, { passive: true }) }, [])
    return (
        <>
            <HeroSection2 />
            <div class="page-content">
                <AboutSection3 />
                <Services2 />
                <PurchaseStarbiz />
                <AboutSectopn4 />
                <BannerAdd2 name={"Latest Work"} name2={"Latest Portfolio"} name3={"Latest Case Studies"} />
                <Project1 />
                <Carousel4 />
                <TeamMember />
                <Carousel2 bg1={bg1} dataOver={"9"} />
                <PricingSection />
                {limit && <Counter flex={"d-flex justify-content-center"} flex2={"d-flex flex-column"} background={"counter-background"} />}
                <BlogSection />
                <ContactSection />
            </div>
        </>
    )
}

export default HomeTwo
