import React, { useState } from 'react'
import Timer from '../../Components/timer/Timer'
import { NavLink } from 'react-router-dom';

const ComingSoon = () => {
    const [inputValue, setInputValue] = useState({
        email: "",
    })

    const onInputChange = (evt) => {
        const { name, value } = evt.target;
        setInputValue((prev) => ({ ...prev, [name]: value }))
    }
    const targetDate = new Date('2023-10-23T00:00:00').getTime();
    return (
        <div class="page-content">
            <section className="vh-100 p-0 dark-bg" data-bg-img="images/pattern-1.png">
                <div className="container h-100">
                    <div className="row align-items-center h-100">
                        <div className="col-lg-7">
                            <div className="coming-soon">
                                <Timer targetDate={targetDate} />{/* Countdown items can be added here */}
                            </div>
                        </div>
                        <div className="col-lg-4 ms-auto">
                            <NavLink className="navbar-brand logo bg-transparent p-0 m-0" to="/">
                                <img className="img-fluid" src="images/logo.png" alt="" />
                            </NavLink>
                            <div className="mt-5">
                                <h4 className="mb-4 text-white">Subscribe to get notified!</h4>
                                <div className="subscribe-form">
                                    <form id="mc-form">
                                        <input
                                            type="email"
                                            value={inputValue.email}
                                            name="email"
                                            className="email form-control"
                                            id="mc-email"
                                            placeholder="Enter your email address"
                                            required
                                            onChange={onInputChange}
                                        />
                                        <div className="primary-btn mt-3">
                                            <input className="subscribe-btn" type="submit" name="subscribe" value="Subscribe Now"/>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ComingSoon
