import React from "react";
import OdometerCount from "../common/Odometer";
import counterApi from "../../api/CounterApi";

const Counter = ({ flex, flex2, background }) => {
  return (
    <section>
      <div className="container">
        <div className={`row ${background}`}>
          {counterApi.map((item, index) => (
            <div className={`col-lg-3 col-md-6 mt-5 mt-lg-0 ${flex}`} key={index}>
              <div className="counter style-1">
                <div className={`counter-top ${flex2}`}>
                  <div className="counter-icon">
                    <i className={item.icon} />
                  </div>
                  <div className="counter-desc d-flex align-items-center">
                    <span className="count-number">
                      <OdometerCount countUp={item.count} />
                    </span>
                    <span>k</span>
                  </div>
                </div>
                <h6>{item.label}</h6>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Counter;
