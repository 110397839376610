/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import Information1APi from '../../api/Information1APi'
import { BlogApi2 } from '../../api/BlogApi/BlogApi2';
import { HowWeWorks } from '../../api/HowWeWorks/HowWeWorks';
import { SideMenu } from '../../api/SideMenu';
import { Link } from 'react-router-dom';

const BlogSection2 = ({ bgImage, selected }) => {

    const [activeAccordion, setActiveAccordion] = useState(null);
    const [Selected, setSelected] = useState(1);

    const handleAccordionClick = (index) => {
        activeAccordion === index ? setActiveAccordion(0) : setActiveAccordion(index);
    };

    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-9 mb-6 mb-lg-0 order-lg-1 ps-lg-5">
                        <div className="service-item">
                            <div className="service-image mb-5">
                                <img src={bgImage} className="img-fluid" alt="..." />
                            </div>
                            <p className="mb-5">We can help you develop and execute a clear and strategic IT roadmap with priorities that are closely linked to business goals. We will work one-on-one with your team to understand your goals.</p>
                            <h3>How we works?</h3>
                            <div className="row mt-4">
                                <div className="col-md-6">
                                    <ul className="list-unstyled list-icon">
                                        {HowWeWorks.map((item, index) => (
                                            <li key={index}>
                                                <i className="las la-check-circle" />{item.list}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul className="list-unstyled list-icon">
                                        {HowWeWorks.map((item, index) => (
                                            <li key={index}>
                                                <i className="las la-check-circle" />{item.list}
                                            </li>
                                        ))}

                                    </ul>
                                </div>
                            </div>
                            <div className="row my-5">
                                {Information1APi.filter((item, index) => index < 3).map((item, index) => {
                                    return (
                                        <div key={index} className="col-lg-4 col-md-6">
                                            <div className="featured-item style-2">
                                                <div className="featured-icon">
                                                    <i className={`flaticon ${item.icon}`} />
                                                    <span className="feature-number">{item.id}</span>
                                                </div>
                                                <div className="featured-desc">
                                                    <div className="featured-title">
                                                        <h5>{item.title}</h5>
                                                    </div>
                                                    <p>{item.details}</p>
                                                </div>
                                            </div>
                                        </div>)
                                })}
                            </div>
                            <div className="accordion" id="accordion">
                                {BlogApi2.map((item, index) => (
                                    <div className={`accordion-item bg-transparent mb-4 ${activeAccordion === item.id ? 'active' : ''}`} key={index}>
                                        <h3 className="accordion-header" id={`heading${index}`}>
                                            <button
                                                className={`accordion-button shadow-none mb-0 bg-transparent ca ${activeAccordion === item.id ? "" : "collapsed"}`}
                                                type="button"
                                                onClick={() => handleAccordionClick(item.id)}
                                            // aria-expanded={activeAccordion === index}
                                            // aria-controls={`collapse${index}`}
                                            >
                                                {item.id}.{item.title}
                                            </button>
                                        </h3>
                                        <div
                                            id={`collapse${index}`}
                                            className={`accordion-collapse collapse ${activeAccordion === item.id ? "show" : ""}`}
                                            aria-labelledby={`heading${index}`}
                                            data-bs-parent="#accordion"
                                            style={{ transition: 'maxHeight 4s ease-in-out ', maxHeight: activeAccordion === item.id ? '500px' : '0' }}
                                        >
                                            <div className="accordion-body">{item.details}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-3">
                        <div className="service-widget widget widget_nav_menu ">
                            <ul id="menu-services-menu" className="menu mb-0">
                                {SideMenu.map((service, index) => {
                                    if (service.id === selected) {
                                        return (
                                            <li key={index} className="menu-item current-menu-item">
                                                <Link to={service.link} onClick={() => setSelected(service.id)}>{service.title}</Link>
                                            </li>
                                        )
                                    } else {
                                        return (
                                            <li key={index} className="menu-item">
                                                <Link to={service.link}>{service.title}</Link>
                                            </li>
                                        )
                                    }
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default BlogSection2