import React from 'react'
import HeroSection4 from '../../Components/heroSection/HeroSection4'
import PricingPlan from '../../Components/pricingPlan/PricingPlan'

const PriceTable = () => {
    return (
        <>
            <HeroSection4 name={"Price Table"} name2={"Home"} />
            <div class="page-content">
                <PricingPlan />
            </div>
        </>
    )
}

export default PriceTable
