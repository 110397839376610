import React from 'react'
import large5 from '../../assets/images/services/large/05.jpg'
import HeroSection4 from '../../Components/heroSection/HeroSection4'
import BlogSection2 from '../../Components/blogSection/BlogSection2'

const WebsiteDevelopment = () => {
  return (
    <>
      <HeroSection4 name={"UI/UX Experience"} name2={"Home"} />
      <BlogSection2 bgImage={large5} selected={5} />
    </>
  )
}

export default WebsiteDevelopment