const AboutApi6 = [
  {
    iconClass: 'flaticon flaticon-idea',
    title: 'Creative Design',
    description: 'There are many variations variations of passages of Lorem Ipsum available.',
  },
  {
    iconClass: 'flaticon flaticon-rocket-launch',
    title: 'SEO Friendly',
    description: 'There are many variations variations of passages of Lorem Ipsum available.',
  },
  {
    iconClass: 'flaticon flaticon-statistics',
    title: 'Website Development',
    description: 'There are many variations variations of passages of Lorem Ipsum available.',
  },
];
export default AboutApi6