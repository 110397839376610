import React from 'react'

const PurchaseStarbiz = () => {
    return (
        <div>
            <section className="p-0">
                <div className="container">
                    <div className="row justify-content-center text-center mb-5">
                        <div className="col-lg-8 col-md-12">
                            <div className="d-md-flex align-items-center rounded-pill border border-primary p-3 ps-5">
                                <h5 className="mb-0 me-4">Grow Your Business With Digital Strategy</h5>
                                <div className="rounded-pill bg-primary py-2 px-10 ms-auto"><h6 className="mb-0">Purchase Starbiz</h6></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default PurchaseStarbiz