import React from 'react'
import large4 from '../../assets/images/services/large/04.jpg'
import HeroSection4 from '../../Components/heroSection/HeroSection4'
import BlogSection2 from '../../Components/blogSection/BlogSection2'

const SocialMediaMarketing = () => {
  return (
    <>
      <HeroSection4 name={"UI/UX Experience"} name2={"Home"} />
      <BlogSection2 bgImage={large4} selected={4} />
    </>
  )
}

export default SocialMediaMarketing