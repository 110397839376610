import React, { useEffect, useState } from "react";
import Odometer from "react-odometerjs";

const OdometerCount = ({ countUp }) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    const timeoutId = setTimeout(() => setValue(countUp), 1000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [countUp]);

  return <Odometer className="count-number" value={value} />;
};

export default OdometerCount;
