import React from 'react'
import Carousel3 from '../reactcarouselSection/Carousel3';

const Services2 = () => {
    return (
        <>
            <section className="pt-0">
                <div className="container">
                    <div className="row justify-content-center text-center mb-5">
                        <div className="col-lg-8 col-md-12">
                            <div className="theme-title">
                                <h6>Our Services</h6>
                                <h2>Giving Your Business Some Great Ideas</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <Carousel3 />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Services2