import React from 'react'
import AboutApi6 from '../../api/AboutApi/AboutApi6'

const AboutSection6 = () => {
    return (
        <>
            <section>
                <div className="container">
                    <div className="row justify-content-center text-center mb-5">
                        <div className="col-lg-8 col-md-12">
                            <div className="theme-title">
                                <h6>What We Do</h6>
                                <h2>We're A Full-service Design Agency</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {AboutApi6.map((item, index) => (
                            <div key={index} className="col-lg-4 col-md-12 mt-5 mt-lg-0">
                                <div className="featured-item style-3">
                                    <div className="featured-icon">
                                        <i aria-hidden="true" className={item.iconClass}></i>
                                    </div>
                                    <div className="featured-desc">
                                        <div className="featured-title">
                                            <h5>{item.title}</h5>
                                        </div>
                                        <p>{item.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutSection6
