import React from "react";
import OwlCarousel from "react-owl-carousel";
import CarouselApi from "../../api/CarouselApi/CarouselApi";
import { NavLink, useNavigate } from "react-router-dom";

const Carousel = ({ margin }) => {
  const navigate = useNavigate()
  const portfolio = (item, index) => {
    console.log("🚀 ~ file: Carousel.js:9 ~ portfolio ~ item:", item)
    localStorage.setItem('portfolio', JSON.stringify(item.img));
    navigate(`/portfolio-single/${index + 1}`)
  }
  const options = {
    items: 1,
    nav: false,
    dots: false,
    loop: true,
    autoplay: true,
    autoplayTimeout: 4000,
    lazyLoad: true,
    lazyContent: true,
    center: true,
    smartSpeed: 450,
  };
  return (
    <>
      <OwlCarousel
        id="custom-customer-testimonoals"
        {...options}
        responsive={{
          0: {
            items: 1, // Show 1 item for screens with width less than or equal to 0px (mobile screens)
          },
          768: {
            items: 2, // Show 3 items for screens with width greater than or equal to 768px (larger screens)
          },
          1000: {
            items: 3, // Show 3 items for screens with width greater than or equal to 768px (larger screens)
          },
        }}
      >
        {CarouselApi?.map((item, index) => {
          return (
            <section key={index} className={`pt-0 item mt ${margin}`}>
              <div className="container">
                <div className="row">
                  <div className="col">
                    <div className="no-pb portfolio-item style-1 ">
                      <div id="portfolio-desc" className="item"></div>
                      <div id="portfolio-desc_container" className=" item">
                        <div className="portfolio-fild">{item.fild}</div>
                        <h4>
                          <div onClick={() => { portfolio(item, index) }}>
                            <NavLink className="portfolio-position" >
                              {item.position}
                            </NavLink>
                          </div>
                        </h4>
                      </div>
                      <img className="portfolio-border-radius" src={item.img} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          );
        })}
      </OwlCarousel>
    </>
  );
};

export default Carousel;
