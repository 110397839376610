const Information1APi = [
    {
        id: 1,
        icon: "flaticon-graphic-design",
        title: "Quality Web Designing",
        details: "There are many variations variations of passages of Lorem Ipsum available.",
    },
    {
        id: 2,
        icon: "flaticon-brand",
        title: "Award Winning Company",
        details: "There are many variations variations of passages of Lorem Ipsum available.",
    },
    {
        id: 3,
        icon: "flaticon-content-management",
        title: "Website Development",
        details: "There are many variations variations of passages of Lorem Ipsum available.",
    },
    {
        id: 4,
        icon: "flaticon-seo-2",
        title: "Seo & Content Writing",
        details: "There are many variations variations of passages of Lorem Ipsum available.",
    },
]
export default Information1APi