import team1 from '../../assets/images/team/01.jpg'
import team2 from '../../assets/images/team/02.jpg'
import team3 from '../../assets/images/team/03.jpg'
import team4 from '../../assets/images/team/04.jpg'

const TeamMembersApi = [
    {
        id: 1,
        name: 'Romi Jensen',
        role: 'Developer',
        social: ['facebook', 'dribbble', 'twitter'],
        image: team1,
        roles: ["Content Marketing", "SEO Services", "Distribution"],
        icon: ["flaticon-content-management", "flaticon-seo-2", "flaticon-seo"],
        description: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable.",
        email: "info@skytouchinfotech.com",
        experience: "7+ Years",
        number: "+91-234-567-8900",
        job: [{ jonname: "Web Development", value: 98 },
        { jonname: "Creative Design", value: 85 },
        { jonname: "Graphic Design", value: 90 },
        { jonname: "WordPress", value: 72 }],
        Information: [{
            icon: "flaticon-content-management",
            title: "Content Marketing",
            description: "There are many variations variations of passages of Lorem Ipsum available."
        }, {
            icon: "flaticon-seo-2",
            title: "SEO Services",
            description: "There are many variations variations of passages of Lorem Ipsum available."
        }, {
            icon: "flaticon-seo",
            title: "Distribution",
            description: "There are many variations variations of passages of Lorem Ipsum available."
        }]
    },
    {
        id: 2,
        name: 'Armani Wild',
        role: 'Technical Director',
        social: ['facebook', 'dribbble', 'twitter'],
        image: team2,
        roles: ["Content Marketing", "SEO Services", "Distribution"],
        icon: ["flaticon-content-management", "flaticon-seo-2", "flaticon-seo"],
        description: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable.",
        email: "info@skytouchinfotech.com",
        experience: "7+ Years",
        number: "+91-234-567-8900",
        job: [{ jonname: "Web Development", value: 98 },
        { jonname: "Creative Design", value: 85 },
        { jonname: "Graphic Design", value: 90 },
        { jonname: "WordPress", value: 72 }],
        Information: [{
            icon: "flaticon-content-management",
            title: "Content Marketing",
            description: "There are many variations variations of passages of Lorem Ipsum available."
        }, {
            icon: "flaticon-seo-2",
            title: "SEO Services",
            description: "There are many variations variations of passages of Lorem Ipsum available."
        }, {
            icon: "flaticon-seo",
            title: "Distribution",
            description: "There are many variations variations of passages of Lorem Ipsum available."
        }]
    },
    {
        id: 3,
        name: 'Melan Smith',
        role: 'Designer',
        social: ['facebook', 'dribbble', 'twitter'],
        image: team3,
        roles: ["Content Marketing", "SEO Services", "Distribution"],
        icon: ["flaticon-content-management", "flaticon-seo-2", "flaticon-seo"],
        description: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable.",
        email: "info@skytouchinfotech.com",
        experience: "7+ Years",
        number: "+91-234-567-8900",
        job: [{ jonname: "Web Development", value: 98 },
        { jonname: "Creative Design", value: 85 },
        { jonname: "Graphic Design", value: 90 },
        { jonname: "WordPress", value: 72 }],
        Information: [{
            icon: "flaticon-content-management",
            title: "Content Marketing",
            description: "There are many variations variations of passages of Lorem Ipsum available."
        }, {
            icon: "flaticon-seo-2",
            title: "SEO Services",
            description: "There are many variations variations of passages of Lorem Ipsum available."
        }, {
            icon: "flaticon-seo",
            title: "Distribution",
            description: "There are many variations variations of passages of Lorem Ipsum available."
        }]
    },
    {
        id: 4,
        name: 'Woakes Lion',
        role: 'Web Designer',
        social: ['facebook', 'dribbble', 'twitter'],
        image: team4,
        roles: ["Content Marketing", "SEO Services", "Distribution"],
        icon: ["flaticon-content-management", "flaticon-seo-2", "flaticon-seo"],
        description: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable.",
        email: "info@skytouchinfotech.com",
        experience: "7+ Years",
        number: "+91-234-567-8900",
        job: [{ jonname: "Web Development", value: 98 },
        { jonname: "Creative Design", value: 85 },
        { jonname: "Graphic Design", value: 90 },
        { jonname: "WordPress", value: 72 }],
        Information: [{
            icon: "flaticon-content-management",
            title: "Content Marketing",
            description: "There are many variations variations of passages of Lorem Ipsum available."
        }, {
            icon: "flaticon-seo-2",
            title: "SEO Services",
            description: "There are many variations variations of passages of Lorem Ipsum available."
        }, {
            icon: "flaticon-seo",
            title: "Distribution",
            description: "There are many variations variations of passages of Lorem Ipsum available."
        }]
    },
    {
        id: 5,
        name: 'Romi Jensen',
        role: 'Developer',
        social: ['facebook', 'dribbble', 'twitter'],
        image: team1,
        roles: ["Content Marketing", "SEO Services", "Distribution"],
        icon: ["flaticon-content-management", "flaticon-seo-2", "flaticon-seo"],
        description: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable.",
        email: "info@skytouchinfotech.com",
        experience: "7+ Years",
        number: "+91-234-567-8900",
        job: [{ jonname: "Web Development", value: 98 },
        { jonname: "Creative Design", value: 85 },
        { jonname: "Graphic Design", value: 90 },
        { jonname: "WordPress", value: 72 }],
        Information: [{
            icon: "flaticon-content-management",
            title: "Content Marketing",
            description: "There are many variations variations of passages of Lorem Ipsum available."
        }, {
            icon: "flaticon-seo-2",
            title: "SEO Services",
            description: "There are many variations variations of passages of Lorem Ipsum available."
        }, {
            icon: "flaticon-seo",
            title: "Distribution",
            description: "There are many variations variations of passages of Lorem Ipsum available."
        }]
    },
];

export default TeamMembersApi