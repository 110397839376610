const ServicesApi2 = [
    {
        iconClass: 'flaticon-mobile-application',
        title: 'Mobile Development',
        link: '/mobile-development',
        list: ['Seo Marketing', 'Web Design', 'Brand Design', 'Digital Production', 'Creative Direction'],
    },
    {
        iconClass: 'flaticon-boost',
        title: 'Business Strategy',
        link: '/business-strategy',
        list: ['Seo Marketing', 'Web Design', 'Brand Design', 'Digital Production', 'Creative Direction'],
    },
    {
        iconClass: 'flaticon-content-marketing',
        title: 'Digital Marketing',
        link: '/digital-marketing',
        list: ['Seo Marketing', 'Web Design', 'Brand Design', 'Digital Production', 'Creative Direction'],
    },
    {
        iconClass: 'flaticon-social-media',
        title: 'Social Media Marketing',
        link: '/social-media-marketing',
        list: ['Seo Marketing', 'Web Design', 'Brand Design', 'Digital Production', 'Creative Direction'],
    },
    {
        iconClass: 'flaticon-software-development',
        title: 'Website Development',
        link: '/website-development',
        list: ['Seo Marketing', 'Web Design', 'Brand Design', 'Digital Production', 'Creative Direction'],
    },
];

export default ServicesApi2