import React from 'react'
import HeroSection4 from '../../Components/heroSection/HeroSection4'
import FaqSection from '../../Components/faqSection/FaqSection'

const Faq = () => {
    return (
        <>
            <HeroSection4 name={"F.A.Q"} name2={"Home"} />
            <div class="page-content">
                <FaqSection />
            </div>
        </>
    )
}

export default Faq
