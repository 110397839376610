import AboutApi3 from "../../api/AboutApi/AboutApi3";

const AboutSection4 = () => {
    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <div className="theme-title">
                            <h6>Why Choose Us</h6>
                            <h2>We Are Starbiz Best Creative Agency Ever</h2>
                        </div>
                    </div>
                    <div className={`col-12 col-lg-8 ps-lg-8 mt-5 mt-lg-0`}>
                        <div className="row">
                            {AboutApi3.map((feature, index) => (
                                <div className={`col-md-6 ${feature.id % 2 === 0 ? "mt-5" : ""}`} key={index}>
                                    <div className="featured-item style-3">
                                        <div className="featured-icon">
                                            <i aria-hidden="true" className={`flaticon ${feature.icon}`}></i>
                                        </div>
                                        <div className="featured-description">
                                            <div className="featured-title">
                                                <h5>{feature.title}</h5>
                                            </div>
                                            <p>{feature.description}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default AboutSection4

