import React from "react";
import AboutApi1 from "../../api/AboutApi1";
import AgencySvg from "../svg/Agency";
import { NavLink } from "react-router-dom";

const AboutSection = ({ img, imgStyle }) => {
  return (
    <>
      <section className="about-section pt-0">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-lg-6 position-relative">
              <img
                className={`img-fluid rounded-bottom${imgStyle} rounded-top-circle`}
                src={img}
                alt="Img1"
              />
              {imgStyle ? "" : <div className="round-text">
                <AgencySvg label={"Creative Agency • Business Agency •"} icon={"flaticon-brand"}/>
              </div>}
            </div>
            <div className="col-12 col-lg-6 ps-lg-5 mt-5 mt-lg-0">
              <div className="theme-title ">
                <h6>About Us</h6>
                <h2>Refreshingly Unique Company About</h2>
                <p>
                  Starbiz discovering the source behind the ubiquitous filler
                  text. In seeing a sample of lorem ipsum, his interest was
                  piqued by consectetur Starbiz discovering the source behind
                  the ubiquitous filler text
                </p>
              </div>
              <div className="row my-4">
                {AboutApi1.map((item, index) => {
                  return (
                    <div key={index} className="col-md-6">
                      <ul className="list-unstyled list-icon">
                        <li>
                          <i className="las la-check-circle" />
                          {item.details}
                        </li>
                      </ul>
                    </div>
                  );
                })}
                {AboutApi1.map((item, index) => {
                  return (
                    <div key={index} className="col-md-6">
                      <ul className="list-unstyled list-icon">
                        <li>
                          <i className="las la-check-circle" />
                          {item.details}
                        </li>
                      </ul>
                    </div>
                  );
                })}
              </div>
              <NavLink className="rounded-button ">
                <i className="flaticon-forward" />
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutSection;
