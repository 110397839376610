import blog1 from "../assets/images/blog/01.jpg";
import blog2 from "../assets/images/blog/02.jpg";
import blog3 from "../assets/images/blog/03.jpg";
const BlogApi = [
  {
    image: blog1,
    date: "July 23, 2023",
    category: "Web Design",
    title: "Starbiz just pushes deeper into Agency",
  },
  {
    image: blog2,
    date: "July 23, 2023",
    category: "Agency",
    title: "Questions You Must Ask Solutions Here",
  },
  {
    image: blog3,
    date: "July 23, 2023",
    category: "Creative",
    title: "Best Reasons Why You Might Want",
  },
];
export default BlogApi;
