import React from 'react'
import HeroSection4 from '../../Components/heroSection/HeroSection4'
import BlogSingle from '../../Components/blogSection/BlogSingle'

const Blog_Single = () => {
    return (
        <>
            <HeroSection4 name={"Blog Single"} name2={"Home"} />
            <div class="page-content">
                <BlogSingle />
            </div>
        </>
    )
}

export default Blog_Single
