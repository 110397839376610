import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';
import ProjectApi2 from '../../api/ProjectApi/ProjectApi2';
import AgencySvg from '../svg/Agency';

const Project2 = () => {
    const owlOptions = {
        items: 4,
        loop: true,
        responsive: {
            0: { items: 1 },
            576: { items: 2 },
            992: { items: 3 },
            1200: { items: 4 },
        },
        margin: 10,
        autoplay: true,
    };

    return (
        <section className="pt-0">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-3 text-center">
                        <div className="round-text">
                            <AgencySvg label={"Latest Work • Portfolio • Project •"} icon={"flaticon-award"} />
                        </div>
                        <div className="theme-title">
                            <h2>We’ve Done Lot’s of Work</h2>
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <OwlCarousel className="owl-carousel" {...owlOptions}>
                            {ProjectApi2.map((item, index) => (
                                <div className="item" key={index}>
                                    <div className="portfolio-item style-3">
                                        <div className="portfolio-image">
                                            <img src={item.imageSrc} className="img-fluid" alt="" />
                                        </div>
                                        <div className="portfolio-desc">
                                            <div className="portfolio-cat ">
                                                <Link to="#" rel="tag" className='text-decoration-none'>
                                                    {item.category}
                                                </Link>
                                            </div>
                                            <h4 >
                                                <Link className="text-decoration-none">{item.title}</Link>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Project2;
