import React, { useState } from 'react'
import AgencySvg from '../svg/Agency'
import OpneModal from '../modal/OpneModal';

const VideoSection2 = () => {
    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };
    return (
        <>
            <div class="col-12 col-lg-6 position-relative">
                <div>
                    <img src={require("../../assets/images/about/08.jpg")} className="img-fluid rounded img-filter" alt="Img1" />
                    <div className="round-text" onClick={openModal}>
                        <AgencySvg label={" Watch Video Now • Watch Video Now •"} icon={"las la-play"} />
                    </div>
                </div>
            </div>
            <OpneModal isOpen={isOpen} closeModal={closeModal} />
        </>
    )
}

export default VideoSection2
