import React, { useState } from "react";
import ServicesApi1 from "../../api/ServicesApi/ServicesApi1";
import { NavLink } from "react-router-dom";

const Services1 = () => {
  const [hoveredServiceIndex, setHoveredServiceIndex] = useState(0);
  return (
    <>
      <section>
        <div className="container">
          {/* ... Other JSX ... */}
          <div className="row">
            <div className="col-12">
              <div className="service-list">
                {ServicesApi1.map((item, index) => (
                  <div
                    key={index}
                    className={`service-item style-1 ${hoveredServiceIndex === index ? "active" : ""
                      }`}
                  >
                    <div className="service-image">
                      {hoveredServiceIndex === index ? (
                        <img
                          src={hoveredServiceIndex === index ? item.img : ""}
                          className="img-fluid"
                          alt={`${item.id}`}
                          decoding="async"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div
                      className="service-desc"
                      onMouseEnter={() => setHoveredServiceIndex(index)}
                    >
                      <h4>
                        <NavLink to={item.path} className="service-title">
                          {item.title}
                        </NavLink>
                      </h4>
                      <p>{item.description}</p>
                      <NavLink to={item.path} className="rounded-button cursor-pointer">
                        <i className="flaticon-forward" />
                      </NavLink>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services1;
