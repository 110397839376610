import React, { useEffect, useState } from 'react'
import BlogLeftApi from '../../api/BlogApi/BlogLeftApi'
import Sidebar from "../sideBar/Sidebar"
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

const BlogLeft = ({ justify }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  // pagination
  const itemsPerPage = 3; //set onepage Totalitem
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const itemsToDiaplay = BlogLeftApi.slice(startIndex, endIndex);
  useEffect(() => {
    setTotalPages(Math.ceil(BlogLeftApi.length / 3))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [BlogLeftApi])
  const handlePageChange = (newPage) => {
    if (newPage > 0) {
      setCurrentPage(newPage)
    }
  }
  const nextPageChange = (newPage) => {
    if (newPage <= totalPages) {
      setCurrentPage(newPage)
    }
  }
  // pagination
  useEffect(() => {
    window.scrollTo(300, 300);
  }, [currentPage]);
  return (
    <>
      <section className="theme-blogs">
        <div className="container">
          <div className="row">
            <div className={justify ? `col-12 col-lg-9 pe-lg-5 mb-6 mb-lg-0` : `col-12 col-lg-9 mb-6 mb-lg-0 order-lg-1 ps-lg-5`}>
              {itemsToDiaplay?.map((post, index) => (
                <div key={index} className="post-card post-classic">
                  <div className="post-image">
                    <img className="img-fluid" src={post.imageSrc} alt="Image1" />
                  </div>
                  <div className="post-description">
                    <ul className="list-inline post-bottom mb-0">
                      <li className="list-inline-item">
                        <i className="flaticon-calendar"></i>
                        <span>{post.date}</span>
                      </li>
                      <li className="list-inline-item">
                        <i className="flaticon-tag"></i>
                        <span className="cat-links">
                          <NavLink  rel="category tag">
                            {post.category}
                          </NavLink>
                        </span>
                      </li>
                    </ul>
                    <div className="post-title">
                      <h4>
                        <Link to={post.link}>{post.title}</Link>
                      </h4>
                    </div>
                    <p>{post.description}</p>
                    <Link className="primary-btn" to={post.link}>
                      <span>Read More</span>
                    </Link>
                  </div>
                </div>
              ))}
              <nav aria-label="Page navigation" className="mt-6">
                <ul className="pagination">
                  <button className='page-link rounded  page-item' onClick={() => handlePageChange(currentPage - 1)}>Previous </button>
                  {
                    Array.from({ length: totalPages }, (_, index) => {
                      return (
                        <div key={index}>
                          <button className={`page-link rounded  page-item ${currentPage === index + 1 ? "text-dark" : ""}`}
                            onClick={() => handlePageChange(index + 1)}

                            disabled={index + 1 === currentPage}
                          >
                            {index + 1}
                          </button>
                        </div>
                      )
                    })
                  }
                  <button className='page-link rounded  page-item' onClick={() => nextPageChange(currentPage + 1)} >Next </button>
                </ul>
              </nav>
            </div>
            <div className={justify ? `col-lg-3 col-12` : `col-12 col-lg-3`}>
              <Sidebar />
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default BlogLeft
