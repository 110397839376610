const FaqApi = [
    {
      title: '1. Why Choose Us For Starbiz?',
      content: 'For the incapable bliss of present souls like mine. I am so happy, my dear friend, so absorbed in the exquisite sense of mere tranquil existence, that I neglect my talents. I should be incapable…',
    },
    {
      title: '2. What Are Your Terms And Conditions?',
      content: 'For the incapable bliss of present souls like mine. I am so happy, my dear friend, so absorbed in the exquisite sense of mere tranquil existence, that I neglect my talents. I should be incapable…',
    },
    {
      title: '3. Prioritize the features your customers need',
      content: 'For the incapable bliss of present souls like mine. I am so happy, my dear friend, so absorbed in the exquisite sense of mere tranquil existence, that I neglect my talents. I should be incapable…',
    },
    {
      title: '4. How to Purchase Extend Licence of this Product?',
      content: 'For the incapable bliss of present souls like mine. I am so happy, my dear friend, so absorbed in the exquisite sense of mere tranquil existence, that I neglect my talents. I should be incapable…',
    },
    {
      title: '5. How to Manage your Own Dashboard?',
      content: 'For the incapable bliss of present souls like mine. I am so happy, my dear friend, so absorbed in the exquisite sense of mere tranquil existence, that I neglect my talents. I should be incapable…',
    },
  ];
  export default FaqApi