import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom'; 
import ServicesApi2 from '../../api/ServicesApi/ServicesApi2';

const ServiceItem = ({ iconClass, title, link, list }) => (
  <div className="item">
    <div className="service-item style-3">
      <div className="service-icon">
        <i className={iconClass}></i>
      </div>
      <div className="service-desc">
        <h4>
          <Link to={link} className='member-name text-decoration-none'>{title}</Link>
        </h4>
        <ul className="list-unstyled">
          {list.map((item, index) => (
            <li key={index}>
              <i className="flaticon-forward"></i>
              {item}
            </li>
          ))}
        </ul>
        <Link className="rounded-button text-decoration-none" to={link}>
          <i className="flaticon-forward"></i>
        </Link>
      </div>
    </div>
  </div>
);

const Services3 = () => {


  return (
    <section className="pt-0">
      <div className="container">
        <div className="row justify-content-center text-center mb-5">
          <div className="col-lg-8 col-md-12">
            <div className="theme-title">
              <h6>Our Services</h6>
              <h2>Creative Digital Solution</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <OwlCarousel
              className="owl-carousel"
              items={3}
              loop={true}
              responsive={{
                0: { items: 1 },
                576: { items: 2 },
                992: { items: 3 },
              }}
              margin={30}
              autoplay={true}
            >
              {ServicesApi2.map((service, index) => (
                <ServiceItem
                  key={index}
                  iconClass={service.iconClass}
                  title={service.title}
                  link={service.link}
                  list={service.list}
                />
              ))}
            </OwlCarousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services3;
