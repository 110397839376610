import React from 'react'
import PriceListApi from '../../api/PriceListApi/PriceListApi1'
import { Link } from 'react-router-dom'


const PriceCard = ({ title, price }) => {
    return (
        <>
            <div>
                <div className="price-title">
                    <h4 className="">{title}</h4>
                </div>
                <div className="price-value">
                    <h2>{price}<span>/Mo</span></h2>
                </div>
                <Link className="primary-btn text-decoration-none" >Get Started</Link>

            </div>
            <div className="price-list">
                <ul className="list-unstyled">
                    {PriceListApi.map((item,index) => (
                        <li key={index}>
                            <i className={`las la-${item.icon}`}></i> {item.details}
                        </li>
                    ))}
                </ul>
            </div>
        </>
    )
}

export default PriceCard