const CommentsApi = [
    {
      author: "Romi Kelly",
      date: "July 19, 2023 at 10:30 am",
      content: "Seuismod dissentiunt ne sit, ad eos iudico qualisque adversarium, tota falli et mei. Esse euismod urbanitas ut sed, et duo scaevola pericula splendide. Primis veritus contentiones nec ad, nec et tantas semper delicatissimi."
    },
    {
      author: "Amber Lony",
      date: "July 19, 2023 at 10:30 am",
      content: "Seuismod dissentiunt ne sit, ad eos iudico qualisque adversarium, tota falli et mei. Esse euismod urbanitas ut sed, et duo scaevola pericula splendide. Primis veritus contentiones nec ad, nec et tantas semper delicatissimi."
    },
    {
      author: "Kendra Young",
      date: "July 19, 2023 at 10:30 am",
      content: "Seuismod dissentiunt ne sit, ad eos iudico qualisque adversarium, tota falli et mei. Esse euismod urbanitas ut sed, et duo scaevola pericula splendide. Primis veritus contentiones nec ad, nec et tantas semper delicatissimi."
    }
  ];
  export default CommentsApi