import React from 'react';
import PriceCard from '../common/PriceCard';

const PricingSection = () => {
    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <div className="theme-title ">
                            <h6>Pricing Plan</h6>
                            <h2>It Help You To Choose Prices To Maximize</h2>
                        </div>
                    </div>
                    <div className="col-12 col-lg-8 ps-lg-8 mt-5 mt-lg-0">
                        <div className="price-table mb-5">
                            <PriceCard title={"Starter"} price={"$199 "} />
                        </div>
                        <div className="price-table">
                            <PriceCard title={"Professional"} price={"$299 "} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PricingSection;
