/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Odometer from 'react-odometerjs';
import ProgressBar from '../common/ProgressBar';
import { NavLink } from 'react-router-dom';

const AboutSection3 = () => {
    const [value, setValue] = useState(0);
    // scrollToTop
    const [limit, setLimit] = useState(false)
    const handleInfinitescroll = async () => {
        // console.log("scrollTop" + document.documentElement.scrollTop); 
        try {
            if (document.documentElement.scrollTop >= 200) {
                setLimit(true)
            }
            else if (document.documentElement.scrollTop === 0) {
                setLimit(false)
            }
        } catch (error) {
            console.log(error);
        }

    }
    // scrollToTop
    useEffect(() => { window.addEventListener('scroll', handleInfinitescroll, { passive: true }) }, [])
    useEffect(() => {
        const timeoutId = setTimeout(() => setValue(23), 1000);
        return () => {
            clearTimeout(timeoutId);
        };
    }, []);


    return (
        <div>
            <section className="about-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-6 position-relative">
                            <div className="row">
                                <div className="col-md-6 mt-10">
                                    <img src={require("../../assets/images/about/03.jpg")} className="img-fluid rounded" alt="Img3" />
                                </div>
                                <div className="col-md-6">
                                    <img src={require("../../assets/images/about/04.jpg")} className="img-fluid rounded" alt="Img4" />
                                    <NavLink to={"/about-us"} className="white-btn mt-4">Learn More</NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 ps-lg-5 mt-5 mt-lg-0">
                            <div className="theme-title ">
                                <h6>About Us</h6>
                                <h2>Refreshingly Unique Company About</h2>
                                <p>Starbiz discovering the source behind the ubiquitous filler text. In seeing a sample of lorem ipsum, his interest was piqued by consectetur Starbiz discovering the source behind the ubiquitous filler text</p>
                            </div>
                            <div className="row mt-4 align-items-center">
                                <div className="col-md-6">
                                    <div className="counter style-2 bg-dark-2 text-center p-5">
                                        <div className="counter-icon">
                                            <i className="flaticon-award" />
                                        </div>
                                        <div className="counter-desc d-flex justify-content-center">
                                            <span className="count-number">
                                                <Odometer value={limit ? value : ""} />
                                            </span>
                                            <span>k</span>
                                        </div>
                                        <h6>Award Winning</h6>
                                    </div>
                                </div>
                                {<div className="col-md-6">
                                    <ProgressBar value={limit ? 92 : ""} title={"Business Success"} />
                                    <ProgressBar value={limit ? 98 : ""} title={"Year Of Work"} />
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default AboutSection3