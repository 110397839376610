import React from 'react'
import HeroSection4 from '../../Components/heroSection/HeroSection4'
import BlogLeft from '../../Components/blogSection/BlogLeft'

const Blog_Left_Sidebar = () => {
    return (
        <>
            <HeroSection4 name={"Blog Left Sidebar"} name2={"Home"} />
            <div class="page-content ">
                <BlogLeft />
            </div>
        </>
    )
}

export default Blog_Left_Sidebar
