import React from 'react'
import HeroSection4 from '../../Components/heroSection/HeroSection4'
import BlogLeft from '../../Components/blogSection/BlogLeft'

const Blog_Right_Sidebar = () => {
  return (
    <>
      <HeroSection4 name={"Blog Right Sidebar"} name2={"Home"} />
            <div class="page-content">
                <BlogLeft justify={"Right"} />
            </div>
    </>
  )
}

export default Blog_Right_Sidebar
