/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

const ContactUsSection = () => {
    const [inputValue, setInputValue] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
        textNumber: "",
    })

    const onInputChange = (evt) => {
        const { name, value } = evt.target;
        setInputValue((prev) => ({ ...prev, [name]: value }))
    }
    const Map = () => (<iframe className="w-100 h-100 border-0" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.840108181602!2d144.95373631539215!3d-37.8172139797516!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65d4c2b349649%3A0xb6899234e561db11!2sEnvato!5e0!3m2!1sen!2sin!4v1497005461921" allowFullScreen />
    )

    return (
        <>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-6 mb-6 mb-lg-0 contact-info">
                            <div>
                                <div className="map mb-6">
                                    <Map />
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-6">
                                        <h5>Our Address</h5>
                                        <p>423B, Road Wordwide Country, USA</p>
                                    </div>
                                    <div className="col-md-6">
                                        <h5>Emails</h5>
                                        <p>
                                            <NavLink to="mailto:info@skytouchinfotech.com">
                                                info@skytouchinfotech.com</NavLink>
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <h5>Phone Number</h5>
                                        <p>
                                            <NavLink to="tel:+912345678900">+91-234-567-8900</NavLink>
                                        </p>
                                    </div>
                                    <div className="col-md-6">
                                        <h5>Follow Us</h5>
                                        <ul className="list-inline p-0 m-0 social-icons social-colored">
                                            <li className="social-facebook">
                                                <NavLink to="/">
                                                    <i className="la la-facebook" />
                                                </NavLink>
                                            </li>
                                            <li className="social-twitter">
                                                <NavLink to="/">
                                                    <i className="la la-twitter" />
                                                </NavLink>
                                            </li>
                                            <li className="social-instagram">
                                                <NavLink to="/">
                                                    <i className="la la-instagram" />
                                                </NavLink>
                                            </li>
                                            <li className="social-linkedin">
                                                <NavLink to="/">
                                                    <i className="la la-linkedin" />
                                                </NavLink>
                                            </li>
                                            <li className="social-dribbble">
                                                <NavLink to="/">
                                                    <i className="la la-dribbble" />
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 ps-lg-10">
                            <div className="bg-dark-2 p-7 rounded">
                                <div className="theme-title ">
                                    <h6>Contact Us</h6>
                                    <h2>Drop A Line!</h2>
                                </div>
                                <form id="contact-form" method="post" action="/">
                                    <div className="messages" />
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group mb-5">
                                                <input id="form_name" type="text" name="firstName" value={inputValue.firstName} placeholder="First Name" required="required" data-error="Name is required." onChange={onInputChange} />
                                                <div className="help-block with-errors" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-5">
                                                <input id="form_name2" type="text" name="lastName" placeholder="Last Name" required="required" data-error="Name is required." onChange={onInputChange} />
                                                <div className="help-block with-errors" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-5">
                                                <input id="form_email" type="email" name="email" placeholder="Email" required="required" data-error="Valid email is required." onChange={onInputChange} />
                                                <div className="help-block with-errors" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-5">
                                                <input id="form_phone" type="tel" name="phone" placeholder="Phone" required="required" data-error="Phone is required" onChange={onInputChange} />
                                                <div className="help-block with-errors" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-5">
                                                <select className="form-select" name='textNumber' value={inputValue.textNumber} aria-label="Default select example" onChange={onInputChange}>
                                                    <option defaultValue="option1">Open this select menu</option>
                                                    <option value={"One"}>One</option>
                                                    <option value={"Two"}>Two</option>
                                                    <option value={"Three"}>Three</option>
                                                </select>
                                                <div className="help-block with-errors" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-5">
                                                <input id="form_subject" type="text" name="subject" placeholder="Subject" required="required" data-error="Subject is required." onChange={onInputChange} />
                                                <div className="help-block with-errors" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <textarea id="form_message" name="message" className="h-auto" placeholder="Message" rows={4} required="required" data-error="Please,leave us NavLink message." defaultValue={""} onChange={onInputChange} />
                                        <div className="help-block with-errors" />
                                    </div>
                                    <div className="mt-5">
                                        <button className="primary-btn">Send Messages
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="map">
                                <Map />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default ContactUsSection
