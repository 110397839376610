import React from 'react'
import AwardApi from '../../api/AwardApi/AwardApi'

const Award = () => {
    return (
        <section className="pt-0">
            <div className="container">
                <div className="row">
                    <div className="col">
                        {AwardApi.map((item,index) => (
                            <div key={index} className="award-items">
                                <div className="award-img">
                                    <img className="img-fluid" src={require("../../assets/images/trophy.png")} alt="Image1" />
                                </div>
                                <div className="award-num">
                                    <h4>{item.awardNum}</h4>
                                </div>
                                <div className="award-title">
                                    <h4>{item.awardTitle}</h4>
                                </div>
                                <div className="award-project">
                                    <h4>{item.awardProject}</h4>
                                </div>
                                <div className="award-years">
                                    <h4>{item.awardYears}</h4>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Award