import React from 'react'
import AboutApi7 from '../../api/AboutApi/AboutApi7'
import VideoSection2 from '../videoSection/VideoSection2'

const AboutSection7 = () => {
    return (
        <>
            <section className="pb-0 about-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-6 mb-5 mb-lg-0">
                            <div className="row">
                                {AboutApi7.map((feature, index) => (
                                    <div key={index} className="col-md-6">
                                        <div className="featured-item style-1">
                                            <div className="featured-icon">
                                                <i aria-hidden="true" className={feature.icon}></i>
                                            </div>
                                            <div className="featured-description">
                                                <div className="featured-title">
                                                    <h5>{feature.title}</h5>
                                                </div>
                                                <p>{feature.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                            <VideoSection2 />
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutSection7
