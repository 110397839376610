const counterApi = [
  {
    icon: "flaticon-project-management-1",
    count: 23,
    label: "Project Completed",
  },
  {
    icon: "flaticon-rating-1",
    count: 12,
    label: "Team Member",
  },
  {
    icon: "flaticon-expert",
    count: 23,
    label: "Awards Winning",
  },
  {
    icon: "flaticon-rating",
    count: 34,
    label: "Happy Customer",
  },
];
export default counterApi;
