import blog1 from "../../assets/images/blog/01.jpg"
import blog2 from "../../assets/images/blog/02.jpg"
import blog3 from "../../assets/images/blog/03.jpg"
import blog4 from "../../assets/images/blog/04.jpg"
import blog5 from "../../assets/images/blog/05.jpg"
const BlogLeftApi = [
  {
    imageSrc: blog1,
    date: 'July 23, 2023',
    category: 'Web Design',
    title: 'Starbiz Just Pushes Deeper Into Agency',
    description: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable. If you are going to use a passage of Lorem Ipsum. You need to be sure there isn’t anything embarrassing hidden in the middle […]',
    link: '/blog-single',
  },
  {
    imageSrc: blog2,
    date: 'July 23, 2023',
    category: 'Web Design',
    title: 'Questions You Must Ask Solutions Here',
    description: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable. If you are going to use a passage of Lorem Ipsum. You need to be sure there isn’t anything embarrassing hidden in the middle […]',
    link: '/blog-single',
  },
  {
    imageSrc: blog3,
    date: 'July 23, 2023',
    category: 'Web Design',
    title: 'Best Reasons Why You Might Want',
    description: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable. If you are going to use a passage of Lorem Ipsum. You need to be sure there isn’t anything embarrassing hidden in the middle […]',
    link: '/blog-single',
  },
  {
    imageSrc: blog4,
    date: 'July 23, 2023',
    category: 'Web Design',
    title: 'Starbiz Just Pushes Deeper Into Agency',
    description: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable. If you are going to use a passage of Lorem Ipsum. You need to be sure there isn’t anything embarrassing hidden in the middle […]',
    link: '/blog-single',
  },
  {
    imageSrc: blog5,
    date: 'July 23, 2023',
    category: 'Web Design',
    title: 'Best Reasons Why You Might Want',
    description: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable. If you are going to use a passage of Lorem Ipsum. You need to be sure there isn’t anything embarrassing hidden in the middle […]',
    link: '/blog-single',
  },
  // ... other posts
];
export default BlogLeftApi