import React, { useEffect, useState } from "react";
import HeroSection1 from "../../Components/heroSection/HeroSection1";
import Information1 from "../../Components/information/Information1";
import AboutSection from "../../Components/aboutSection/AboutSection";
import BannerAdd from "../../Components/bannerAdd/BannerAdd";
import Services1 from "../../Components/servicesSection/Services1";
import BannerAdd2 from "../../Components/bannerAdd/BannerAdd2";
import AboutSection2 from "../../Components/aboutSection/AboutSection2";
import VideoSection from "../../Components/videoSection/VideoSection";
import Carousel from "../../Components/reactcarouselSection/Carousel";
import Carousel2 from "../../Components/reactcarouselSection/Carousel2";
import Counter from "../../Components/counter/Counter";
import BlogSection from "../../Components/blogSection/BlogSection";
import ContactSection from "../../Components/contactSection/ContactSection";
import about1 from "../../assets/images/about/01.jpg"

const HomeOne = () => {
  const [limit, setLimit] = useState()
  const handleInfinitescroll = async () => {
    // console.log("scrollTop" + document.documentElement.scrollTop); 
    try {
      if (document.documentElement.scrollTop >= 4500) {
        setLimit(true)
      }
      else if (document.documentElement.scrollTop === 0) { setLimit(false) }
    } catch (error) {
      console.log(error);
    }

  }
  useEffect(() => { window.addEventListener('scroll', handleInfinitescroll, { passive: true }) }, [])
  return (
    <>
      <HeroSection1 />
      <div class="page-content">
        <Information1 />
        <AboutSection img={about1} />
        <BannerAdd addName={"Creative Agency"} addName2={"Business Agency"} addName3={"Design Agency"} addName4={"Marketing Agency "} />
        <Services1 />
        <BannerAdd2 name={"Latest Work"} name2={"Latest Portfolio"} name3={"Latest Case Studies"} />
        <Carousel />
        <AboutSection2 />
        <VideoSection />
        <BannerAdd2 name={"Testimonial"} name2={"Client Feedback"} name3={"Awesome Review"} />
        <Carousel2 />
        {limit ? <Counter /> : ""}
        <BlogSection />
        <ContactSection />
      </div>
    </>
  );
};

export default HomeOne;
