

const pricingPlansApi = [
    {
        title: "Starter",
        price: "$199",
        features: [
            "Upto 5 design revisions",
            "Unlimited Site licenses",
            "WordPress Website",
            "50gb Web Storage",
            "24/7 Customer Support"
        ]
    },
    {
        title: "Professional",
        price: "$299",
        features: [
            "Upto 5 design revisions",
            "Unlimited Site licenses",
            "WordPress Website",
            "50gb Web Storage",
            "24/7 Customer Support"
        ]
    }
];



export default pricingPlansApi
